import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import target from '../../db/target';

const EditPrice = ({ packageprice, getAllPackagePrices }) => {

    
    const user = useSelector(userSelector)

    const {_id, id, title, price, description, oneperson, onechild } = packageprice

    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)

    const _id_ = useRef();
    const _price = useRef();
    const _title = useRef();
    const _oneperson = useRef();
    const _onechild = useRef();
    const _description = useRef();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = () => {
        setEditState(true)

        console.log(price, parseInt(_price.current.value));

        if (_id_.current.value === '' || _price.current.value === '' || _title.current.value === '' || _description.current.value === ''  || _oneperson.current.value === ''  || _onechild.current.value === '') {
            alert('All details are required')
            setEditState(false)

        } else if (parseInt(_id_.current.value) === id && parseInt(_price.current.value) === price && _title.current.value === title && _description.current.value === description && _oneperson.current.value === oneperson && _onechild.current.value === onechild) {
            console.log('No update');
            alert('No update')
            setEditState(false)
        } else {
            axios.post(target + 'api/packageprice/update', {
                id: { _id },
                packageprice: {
                    id: parseInt(_id_.current.value),
                    price: parseInt(_price.current.value),
                    title: _title.current.value,
                    oneperson: _oneperson.current.value,
                    onechild: _onechild.current.value,
                    description: _description.current.value
                }
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    // console.log(res)
                    handleClose()
                    getAllPackagePrices()
                    setEditState(false)
                })
                .catch(err => {
                    if(err.response.status === 403){
                        window.location.reload()
                    }
                // console.log(err.response.status);
                    console.log(err)
                    setEditState(false)
                    alert(err.response.data)
                })
        }
    }


    return (
        <div>
            <Button size='sm'  onClick={handleShow}>edit</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupCode">
                            <Form.Label column="sm">ID</Form.Label>
                            <Form.Control ref={_id_} size="sm" type="number" defaultValue={id}  readOnly/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">Title</Form.Label>
                            <Form.Control ref={_title} size="sm" type="text" defaultValue={title} readOnly/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupCode">
                            <Form.Label column="sm">Price ($)</Form.Label>
                            <Form.Control ref={_price} size="sm" type="number" defaultValue={price} />
                        </Form.Group>                      
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">OnePerson</Form.Label>
                            <Form.Control ref={_oneperson} size="sm" type="text" defaultValue={oneperson} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">OneChild</Form.Label>
                            <Form.Control ref={_onechild} size="sm" type="text" defaultValue={onechild} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label column="sm">Description</Form.Label>
                            <Form.Control ref={_description} as="textarea" rows={3} size="sm" defaultValue={description} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleEdit}> Save Changes <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditPrice
