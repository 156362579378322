import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import SpinnerSM from '../Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import axios from 'axios';
import './style.css'
import Spinner from '../Spinner/Spinner';
import target from '../../db/target';



const ImageCard = ({imageURL, fileName, size = '', dispatch, selectedImageCount, maxselectableImages }) => {

    const [state, setSate] = useState(false)

    function selectHandler() {
        if (!state && selectedImageCount < maxselectableImages) {
            setSate(true)
            dispatch({
                type: 'select',
                fileName
            })
        } else {
            setSate(false)
            dispatch({
                type: 'deselect',
                fileName
            })
        }
    }

    return <div className='image-box img-thumbnail image-box-select' onClick={selectHandler}>
        <img src={imageURL} width={size === 'sm' ? 100 : 180} className="room-imgs  d-inline" alt="..." />
        <div className="image-select-footer m-2">
            <Form.Check className='m-0' aria-label="option 1" checked={state} onChange={() => { }} />
            <h6 className='m-0' style={{ fontSize: '11px' }} >{fileName}</h6>
        </div>
    </div>
}


const ImageSelector = ({title = 'Select Images', imageURL, dirType='room_images',  maxselectableImages = 4, dispatch , actionType='selected'}) => {

    const [selectedImageCount, setSelectedImageCount] = useState(0)


    const reducer = (state, action) => {
        if (action.type === 'select') {
            state.push(action.fileName)
            setSelectedImageCount(state.length)

        } if (action.type === 'deselect') {
            state = state.filter(item => item !== action.fileName)
            setSelectedImageCount(state.length)

        } if (action.type === 'clear') {
            state = []
            setSelectedImageCount(state.length)

        } else if (action.type === 'error') { }
        return state
    }

    const user = useSelector(userSelector)

    const [selectedImages, dispatchImages] = useReducer(reducer, [])
    const [allImages, setAllImages] = useState(null)
    const [show, setShow] = useState(false);


    function getImages() {
        // console.log('Requested.. for Images');

        axios.get(target + 'api/images/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            params: { dir: dirType }
        })
            .then(res => {
                // console.log(res.data);
                setAllImages(res.data)
            })
            .catch(error => {
                console.log(error)
                setShow(false)
            })
    }

    const handler = () => {        
        // console.log("SELECTED-IMAGES: ",selectedImages);

        if(selectedImages.length !== 0){
            dispatch({
                type: actionType,
                images: selectedImages
            })
        }
        setShow(false)
        dispatchImages({ type: 'clear' })
    }

    return (
        <div className='mt-1' style={{textAlign:'right'}}>
            <Button variant='outline-primary' size='sm' className="me-2 mb-2" onClick={() => {
                getImages()
                setShow(true)
                }}>{title}</Button>

            <Modal className='p-2' show={show} size="xl" onHide={() => {
                setShow(false)
                dispatchImages({ type: 'clear' })
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{title} <span style={{ fontSize: '16px', fontWeight: 400, }} className='border pt-1 pb-1 ps-2 pe-2 rounded ms-1'>{selectedImageCount}/{maxselectableImages}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="room-images-box">
                        {
                            allImages
                                ?
                                allImages.map((fileName, index) => {
                                    return <ImageCard key={index} imageURL={imageURL + fileName} fileName={fileName} dispatch={dispatchImages} size={dirType === 'room_images' ? '' : 'sm'} selectedImageCount={selectedImageCount} maxselectableImages={maxselectableImages} />
                                })
                                :
                                <Spinner state={true} />
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={() => {
                            setShow(false)
                            dispatchImages({ type: 'clear' })
                        }
                        }>Close</Button>
                    <Button variant="primary" type="submit" onClick={handler}> Select </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ImageSelector
