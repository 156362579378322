import React from 'react'
import './style.css'
import { Divider } from '@mui/material'
import BookingTable from './BookingTable'
import Spinner from '../../components/Spinner/Spinner'
import TodayActivity from './TodayActivity'

const loaded = true

export const Dashboard = () => {

  return (
    <div className='dashboard'>
      
      <h4 className="title ">Today Activity</h4>
      <TodayActivity />
{/* 
      <div className="box">
        <div className="box-item"></div>
        <div className="box-item"></div>
        <div className="box-item"></div>
      </div> */}

      <h4 className="title mt-4">Bookings</h4>
      {loaded ? <BookingTable/> : <Spinner/>}
  
    </div>
  )
}
