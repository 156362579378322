
import React, { useState } from 'react'
import { Badge, Button, Modal, Stack } from 'react-bootstrap';

const BadgeCard = ({ facility }) => {

    return <>
        <Badge pill style={{ marginRight: '5px', marginBottom: '2px', cursor: 'pointer' }} >{facility.title}  </Badge>
    </>
}

const ViewRoom = ({ room, endPoint }) => {

    const { code, title, description, images, facilities, packageSelect } = room

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Button size='sm' onClick={handleShow}>View</Button>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Code: {code}</h6>
                    {/* <h6 className='ms-4 mb-2'>{code}</h6> */}
                    <hr />
                    <label>Title:</label>
                    <h6 className='ms-4'>{title}</h6>
                    <hr />
                    <label>Description:</label>
                    <h6 className='ms-4' style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}>{description}</h6>
                    <hr />
                    <label htmlFor="images-display">Images</label>
                    <div className=" mt-2 mb-4 room-images-box img-thumbnail" id='images-display'>
                        {
                            images.map((imageUrl, index) => {
                                return <img key={index} src={endPoint + imageUrl} width={180} className="room-imgs m-1  d-inline" alt="There is a image"></img>
                            })
                        }
                    </div>
                    <label htmlFor="images-display">Facilities</label>
                    <div className="room-images-box img-thumbnail" id='images-display'>
                                <Stack direction="horizontal" gap={2} style={{ display: 'inline-block' }} >
                                    {
                                        facilities.map((facility, index) => {
                                            return <BadgeCard facility={facility} key={index} />
                                        })
                                    }
                                </Stack>
                            </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}> OK </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    // return (
    //     <div className="modal modal-lg  fade" id="view-room" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    //         <div className="modal-dialog">
    //             <div className="modal-content">
    //                 <div className="modal-header">
    //                     <h1 className="modal-title fs-5 view-text" id="staticBackdropLabel">View a room</h1>
    //                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
    //                 </div>
    //                 <div className="modal-body pt-4">
    //                     <h6>Code: {code}</h6>
    //                     {/* <h6 className='ms-4 mb-2'>{code}</h6> */}
    //                     <hr />
    //                     <label>Title:</label>
    //                     <h6 className='ms-4'>{title}</h6>
    //                     <hr />
    //                     <label>Description:</label>
    //                     <h6 className='ms-4'>{description}</h6>
    //                     <hr />
    //                     <label htmlFor="images-display">Images</label>
    //                     <div className=" mt-2 mb-4 room-images-box img-thumbnail" id='images-display'>
    //                         {
    //                             images.map((imageUrl, index) => {
    //                                 return <img key={index} src={endPoint + imageUrl} width={180} className="room-imgs m-1  d-inline" alt="There is a image"></img>
    //                             })
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="modal-footer">
    //                     {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
    //                     <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default ViewRoom
