import React from 'react'

const Spinner = ({ state = true }) => {
    return (
        <>
            {state 
            ? 
            <div className="ms-2 spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            : 
            ''
            }
        </>
    )
}

export default Spinner
