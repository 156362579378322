import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const ViewPrice = ({ packageprice }) => {

    const { id, title, price, description, oneperson, onechild } = packageprice

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div>
            <Button size='sm' onClick={handleShow}>view</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>ID:</label>
                    <h6 className='ms-4'>{id}</h6>
                    <hr />
                    <label>Price:</label>
                    <h6 className='ms-4'>{price}</h6>
                    <hr />
                    <label>Title:</label>
                    <h6 className='ms-4'>{title}</h6>
                    <hr />
                    <label>OnePerson:</label>
                    <h6 className='ms-4'>{oneperson}</h6>
                    <hr />
                    <label>OneChild:</label>
                    <h6 className='ms-4'>{onechild}</h6>
                    <hr />
                    <label>Description:</label>
                    <h6 className='ms-4'>{description}</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>OK </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewPrice
