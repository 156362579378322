import { useEffect, useReducer, useState } from 'react'
import './style.css'
import { Button, ListGroup, ListGroupItem, Table } from 'react-bootstrap'
import EditFasility from '../Facilities/EditFacility'
import DeleteAccount from './DeleteAccount'
import AddAccount from './AddAccount'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import EditAccount from './EditAccount'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import axios from 'axios'
import target from '../../db/target'


const Account = ({ user, getAllAccounts, userAdmin, authRole }) => {

    const {_id, username, email, role } = user

    return <tr>
        <td>{username}</td>
        <td>{email}</td>
        <td>{role}</td>
        <td >
            <div style={{ float: 'right', display: 'flex', gap: '20px' }}>
               { authRole ?  <EditAccount user={user} getAllAccounts={getAllAccounts} roleEditable={username === userAdmin}/> : ''  }
               { authRole ?   <DeleteAccount username={username} id={_id} getAllAccounts={getAllAccounts} deleteable={username === userAdmin}/> : ''  }
            </div>
        </td>
    </tr>
}


export const Accounts = () => {

    const user = useSelector(userSelector)    

    const authRole =  user !== null ? (user.user.role === 'admin' ? true : false) : false

    
    const [accounts, setAccounts] = useState([])
    const [refresh, setRefresh] = useState(false)

    // const reduser = (state, action) => {

    //     return state
    // }

     function getAllAccounts() {
        setRefresh(true)

        axios.get(target + 'api/account/all', {
            headers: {'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
        .then(res => {
            // console.log(res.data);

            setAccounts(res.data)
            setRefresh(false)
        })
        .catch(error => {
            console.log(error)
            // alert(error.message)
            setRefresh(false)
        })
    }


    useEffect(() => {
        setTimeout(() => {
            getAllAccounts()
        }, 1000);
     }, [])
 


    return (
        <div className='calendarview'>
            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="title">Accounts</h3>
                { authRole ? <AddAccount getAllAccounts={getAllAccounts}/> : ''}
            </div>


            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                <Button size='sm' onClick={getAllAccounts} >Refresh<SpinnerSM state={refresh} /></Button>
                {/* <h6 className={`mt-1 mb-0 invalid-data-all ${true ? 'invalid' : ''}`}>OK</h6> */}

                <Table>
                    <thead>
                        <tr>
                            <th>username</th>
                            <th>email</th>
                            <th>role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((_user, index) => {
                                return <Account key={index} user={_user} userAdmin={user.user.username}  getAllAccounts={getAllAccounts} authRole={authRole}/>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
