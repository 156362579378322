import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import CalendarLink from '../Price/CalendarLink'
import Calender from './Calender'
import CalenderControler from './CalenderControler'
import RoomState from './RoomState'
import './style.css'
import { useState } from 'react'

export const CalendarView = () => {
  // console.log(moment().set('month', 6).set('date', 12));


  const user = useSelector(userSelector)
  const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false
 
  const [refresh, setRefresh] = useState(false)

  return (
    <div className='calendarview'>
      <h3 className="title">Calendar</h3>
      <Calender refreshState={refresh} setRefresh={setRefresh}/>
      <h3 style={{marginTop: '40px'}} className="title">Room State</h3>
      <RoomState refresh={refresh} setRefresh={setRefresh}/>
      <hr />
            <div className='room-price-card' style={{ marginBottom: '50px' }}>
                <h6 className="title"> Calendar links</h6>

                <CalendarLink authRole={authRole} />

            </div>
    </div>
  )
}
