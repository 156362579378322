import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Form, InputGroup, Modal, OverlayTrigger, Stack, Table, Tooltip } from 'react-bootstrap'
import CalenderControler from './CalenderControler'
import addBooking from './calenderMethods'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import target from '../../db/target'


// const date = 
//     {
//         day: 1,
//         month: 'Aug'
//     }


const HeaderBox = () => {
    return <th style={{ position: 'sticky' }}>
        <div className="calender-header-box-acc" style={{ width: '60px' }} >
            <p style={{ margin: '0', fontWeight: '400' }}>Rooms</p>

        </div>
    </th>
}

const RoomBox = ({ code }) => {
    return <th style={{ height: '30px', maxHeight: '50px', padding: 0 }}>
        <div className="calender-header-box-acc">
            <p style={{ margin: '0' }}>{code}</p>
        </div>
    </th>
}


const DateHeader = ({ date }) => {
    return <th>
        <div className="calender-header-box-acc">
            <p style={{ margin: '0' }}>{date.day}</p>
            <p style={{ margin: '0' }}>{date.month}</p>
        </div>
    </th>
}

const DataCard = ({ data }) => {

    // console.log(data);

    const state = data.state;
    var description = ''

    let color = 'white'
    let ecolor = 'green'
    let scolor = 'blue'

    function getColor(state) {
        if (state === 'Booked') return '#cef093'
        else if (state === 'CLOSED - Not available') return '#9090ff'
        else if (state === 'External') return '#b0c4de'
        else if (state === 'Blocked') return '#ffd0d0'
        else return 'white'
    }

    if (state === 0 || state === 1 || state === 2) {
        color = getColor(data.order.state)
        description = data.order.description ?? ''

    } else if (state === 3) {
        // console.log('estate', state,  data.estate);
        description = data.sstate.order.description ?? ''
        ecolor = getColor(data.estate.order.state)
        scolor = getColor(data.sstate.order.state)

    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // setShow(true)
    };

    const renderTooltip = (props) => {
        return (
            <Tooltip id="button-tooltip"  {...props}>
                <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{description}</p>
            </Tooltip>
        )
    };

    return <th className="calender-header-box-acc-card">

        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <div className="calender-header-box-acc-data" onClick={handleShow}>
                {state === 0 ? <div className="calender-data-side-right" style={{ marginLeft: state === 0 ? '26px' : '2px', backgroundColor: color }}> <div className='calender-data-side-order' style={{ fontWeight: '600' }}>{data.order.id}</div> </div> : ''}
                {state === 1 ? <div className="calender-data-side-full" style={{ backgroundColor: color }}>  </div> : ''}
                {state === 2 ? <div className="calender-data-side-left" style={{ backgroundColor: color }} ></div> : ''}
                {state === 3 ? <div className="calender-data-side-both"  >
                    <div className='calender-data-side-both-end' style={{ backgroundColor: ecolor }}></div>
                    <div className='calender-data-side-both-start' style={{ backgroundColor: scolor }}><div className='calender-data-side-order' style={{ fontWeight: '600' }}>{data.sstate.order.id}</div></div>
                </div> : ''}
            </div>
        </OverlayTrigger>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Order Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text>Price</InputGroup.Text>
                        <Form.Control type='number' aria-label="First name" defaultValue={67} />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text></InputGroup.Text>
                        <Form.Control type='number' aria-label="First name" defaultValue={67} />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text>Price</InputGroup.Text>
                        <Form.Control type='number' aria-label="First name" defaultValue={67} />
                    </InputGroup>
                    <InputGroup size="sm">
                        <InputGroup.Text>Description</InputGroup.Text>
                        <Form.Control as="textarea" aria-label="With textarea" />
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>OK </Button>
            </Modal.Footer>
        </Modal>
    </th>
}


// const startDate = dayjs()
// const endDate = startDate.add(startDate.daysInMonth(), 'day')

// console.log(startDate);
// console.log(endDate);

let initialDate = new dayjs(0)


const Calender = ({ refreshState, setRefresh }) => {

    const user = useSelector(userSelector)


    const [sDate, setSDate] = useState()
    const [eDate, setEDate] = useState()
    const [aData, setAData] = useState()
    const [dateArray, setDateArray] = useState([])
    const [dataArray, setDataArray] = useState({})
    const [rooms, setRooms] = useState([])
    const [refreshCalander, setRefreshCalander] = useState(false)

    const setAsCustom = (startDate, endDate) => {

        startDate = dayjs(startDate)
        endDate = dayjs(endDate)

        setSDate(startDate)
        setEDate(endDate)


        const array = []

        const data = []

        initialDate = startDate

        // console.log(initialDate);
        let date = startDate
        for (let day = 0; date.isBefore(endDate, 'day') || date.isSame(endDate, 'day'); day++) {

            array.push({
                day: date.date(),
                month: date.format('MMM')
            })
            data.push({})
            date = date.add(1, 'day');
        }
        // console.log(date.isBefore(endDate, 'day'), date.isSame(endDate, 'day'));

        setDateArray(array)
        setDataArray(data)

        // setSDate(startDate)
        // setEDate(endDate)
        _refresh(startDate, endDate, array, data)
    }

    const setAsMonth = (initDate) => {
        const array = []
        const data = []

        let date = initDate.set('date', 1)
        initialDate = date
        // console.log(initialDate);

        const daysInMonth = initDate.daysInMonth()
        // console.log(daysInMonth);

        for (let day = 1; day <= daysInMonth; day++) {
            array.push({
                day,
                month: date.format('MMM')
            })

            data.push({})

            // if(date.isSame(start, 'day')){
            //     data.push({
            //         state: 0,
            //         order: '#3457'
            //     })
            // } else if (date.isAfter(start, 'day') && date.isBefore(end, 'day')){
            //     data.push({
            //         state: 1
            //     })
            // } else if (date.isSame(end, 'day')){
            //     data.push({
            //         state: 2
            //     })
            // } else {
            //     data.push({})
            // }
            date = date.add(1, 'day');
        }
        // console.log(data);
        setDateArray(array)
        setDataArray(data)

        // console.log(initDate.set('date', 1), date);
        // setSDate(initDate.set('date', 1))
        // setEDate(date)
        _refresh(initDate.set('date', 1), date, array, data)
    }


    const _refresh = (_startDate, _endDate, arrayA, dataA) => {

        setRefreshCalander(true)

        setSDate(_startDate)
        setEDate(_endDate)
        setAData(dataA)

        axios.get(target + 'api/cbook/rooms', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            // console.log(res.data);
            setRooms(res.data)

            const roomsCount = res.data
            // console.log(roomsCount);

            // console.log(_startDate, _endDate);

            axios.post(target + 'api/cbook/range', {
                start: _startDate.format('YYYY-MM-DD'),
                end: _endDate.format('YYYY-MM-DD')
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(data => {
                    console.log(data);

                    let roomsResults = {}

                    roomsCount.map((code) => {
                        roomsResults = {
                            ...roomsResults,
                            [code]: dataA
                        }
                    })

                    // console.log(roomsResults);

                    data.data.map((item, index) => {

                        roomsResults = {
                            ...roomsResults,
                            [item.code]: addBooking(roomsResults[item.code], initialDate, new dayjs(item.start), new dayjs(item.end), { id: item.id, state: item.title, description: item.description })
                        }

                    })
                    // console.log(roomsResults[203]);
                    setDataArray(roomsResults)
                    setRefreshCalander(false)

                })
        })
            .catch(error => {
                if (error.response.status === 403) {
                    window.location.reload()
                }
                console.log(error.message)
                setRefreshCalander(false)
            })
    }

    const refresh = () => {
        _refresh(sDate, eDate, [], aData)
        setRefresh(!refreshState)
    }

    useEffect(() => {
        setAsMonth(new dayjs())
        // setTimeout(() => {
        //     refresh()
        // }, 2000);
    }, [])


    return (
        <div>
            <CalenderControler setAsMonth={setAsMonth} setAsCustom={setAsCustom} refresh={refresh} />
            <Card>
                {/* <Button onClick={run}>Click</Button> */}
                <Stack direction="horizontal" jus>
                    <Button size='sm' style={{ width: 'fit-content', margin: '10px' }} onClick={refresh}>Refresh<SpinnerSM state={refreshCalander} /></Button>
                    {/* <div className='calander-header-items'>

                    </div> */}
                    <Stack direction="horizontal" gap={2} className='ms-auto pe-2'>
                        <div style={{ backgroundColor: "#cef093", fontSize: '12px', padding: '2px 8px', height: '22px', borderRadius: '11px', border: '1px solid black'  }}>Booked</div>
                        <div style={{ backgroundColor: "#9090ff", fontSize: '12px', padding: '2px 8px', height: '22px', borderRadius: '11px', border: '1px solid black'  }}>CLOSED - Not available</div>
                        <div style={{ backgroundColor: "#b0c4de", fontSize: '12px', padding: '2px 8px', height: '22px', borderRadius: '11px', border: '1px solid black'  }}>External</div>
                        <div style={{ backgroundColor: "#ffd0d0", fontSize: '12px', padding: '2px 8px', height: '22px', borderRadius: '11px', border: '1px solid black'  }}>Blocked</div>
                        <div style={{ backgroundColor: "white", fontSize: '12px', padding: '2px 8px', height: '22px', borderRadius: '11px', border: '1px solid black' }}>Other</div>
                    </Stack>
                </Stack>

                <div className='calender-box'>

                    <Table striped={false} responsive bordered >
                        <thead>
                            <tr >
                                <HeaderBox />
                                {
                                    dateArray.map((date, index) => {
                                        return <DateHeader key={index} date={date} />
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>

                            {
                                rooms.map((code, index) => {
                                    // console.log(dataArray);
                                    if (dataArray[code] !== undefined) {
                                        return <tr key={index}>
                                            <RoomBox code={code} />
                                            {
                                                (dataArray[code]).map((data, _index) => {
                                                    return <DataCard key={_index} data={data} />
                                                })
                                            }
                                        </tr>
                                    }
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Card>
        </div>
    )
}
export default Calender
