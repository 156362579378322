
import { useRef, useState } from 'react';
import axios from 'axios';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import Button from 'react-bootstrap/Button';
import { CloseButton, Form, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';



const ImageCard = ({ imageURL, fileName, dispatch, size = '', type = 'room_images', deleteble = true, deleteurl='api/images' }) => {

    const user = useSelector(userSelector)

    const [errorMsgState, setErrorMsgState] = useState(false)

    const [show, setShow] = useState(false);
    const [deleteState, setDeleteState] = useState(false)
    const deleteComform = useRef()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function deleteFile() {
        console.log(type);

        if (deleteComform.current.value !== 'DELETE') {
            setErrorMsgState(true)
            setTimeout(() => setErrorMsgState(false), 3000);

        } else {
            setErrorMsgState(false)

            // console.log('FileName:', fileName);

            setDeleteState(true)

            axios.delete(target + deleteurl, {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                params: {
                    dir: type,
                    fileName
                }
            })
                .then(res => {
                    if (res.status === 202) {
                        console.log('DELETE-OK: ', res);
                        setDeleteState(false)
                        setShow(false)
                        dispatch({ type: 'deleted', refresh: true })
                    }

                })
                .catch(error => {
                    console.error('DELETE-ERROR: ', error)
                    setDeleteState(false)
                    dispatch({
                        type: 'error', 
                        data: {
                            title: 'Deleting Warning',
                            message: error.message,
                            state: 'danger',
                        }
                    })
                })
        }
    }
    // style={{ maxWidth: `${(size === 'sm') ? '120px' : '180px'}` }} 
    return <div className="image-box img-thumbnail  " >
        <div className='close-btn-box'>
        {deleteble ?  <CloseButton className='close-btn' onClick={handleShow} /> : ''}
        </div>
        
        
        <img src={imageURL} width={size === 'sm' ? 100 : 180} className="room-imgs  d-inline" alt="..." />
        <h6 className='m-2' style={{ fontSize: '11px' }} >{fileName}</h6>

        {/* <Button variant="danger" onClick={handleShow}>Delete +</Button> */}

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete The {type === 'room_images' ? 'Images' : 'Icons' }</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <label htmlFor="exampleFormControlInput1" className="form-label delete-text ">write "<span>DELETE</span>" to delete</label>
                <input ref={deleteComform} type="text" className="form-control" id="exampleFormControlInput1" />
                {errorMsgState ? <h6 style={{ color: 'red', fontSize: '11px', paddingLeft: '2px', paddingTop: '2px' }}>Enter the word correct</h6> : ''}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="danger" type="submit" onClick={deleteFile}> Delete <SpinnerSM state={deleteState} /></Button>
            </Modal.Footer>

        </Modal>

        
    </div>
}



export default ImageCard
