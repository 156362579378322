import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import DiscountAndTaxAdd from './DiscountAndTaxAdd'
import DiscountAndTaxEdit from './DiscountAndTaxEdit'
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import target from '../../db/target'
import dayjs from 'dayjs'


const DiscountAndTaxCard = ({ data, getAllDiscouts }) => {


    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    return <tr>
        <th style={{ fontWeight: '400' }}>{data.title}</th>
        <th style={{ fontWeight: '400' }}>{data.method}</th>
        <th style={{ fontWeight: '400' }}>{data.operator === '+' ? 'add (+)' : 'sub (-)'}</th>
        <th style={{ fontWeight: '400' }}>{data.limit}</th>
        <th style={{ fontWeight: '400' }}>{data.amount}</th>
        <th style={{ fontWeight: '400' }}>{new dayjs(data.start).format('YYYY-MM-DD')}</th>
        <th style={{ fontWeight: '400' }}>{new dayjs(data.end).format('YYYY-MM-DD')}</th>
        {
            authRole
                ?
                <th style={{ fontWeight: '400', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                    <DeleteItem code='DELETE' title={data.title} endpoint={`${target}api/discount-tax/`} params={{ _id: data._id }} refresh={getAllDiscouts} />
                    <DiscountAndTaxEdit data={data} getAllDiscouts={getAllDiscouts} />
                </th>
                : ''
        }
    </tr>
}


const DiscountAndTax = () => {

    const [refresh, setRefresh] = useState()
    const [discounttax, setDiscountTax] = useState([])

    // const data = {
    //     title: 'Discount',
    //     method: 'value',
    //     operator: '-',
    //     limit: 50,
    //     amount: 10,
    // }

    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false


    function getAllDiscouts() {

        axios.get(target + 'api/discount-tax/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            setDiscountTax(res.data)
        })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        getAllDiscouts()
    }, [])

    return (
        <div className='room-price-card' style={{ marginBottom: '50px' }}>
            <h6 className="title"> Discount & Tax</h6>

            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button size='sm' onClick={getAllDiscouts} >Refresh<SpinnerSM state={refresh} /></Button>
                    {authRole ? <DiscountAndTaxAdd getAllDiscouts={getAllDiscouts} /> : ''}

                </div>

                <Table>
                    <thead>
                        <tr>
                            <th>title</th>
                            <th>method</th>
                            <th>operator</th>
                            <th>limit</th>
                            <th>amount</th>
                            <th>start</th>
                            <th>end</th>
                            {authRole ? <th></th> : ''}

                        </tr>
                    </thead>
                    <tbody>

                        {
                            discounttax.map((data, index) => {
                                return <DiscountAndTaxCard data={data} key={index} getAllDiscouts={getAllDiscouts} />
                            })
                        }

                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default DiscountAndTax
