import React, { useRef, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

import SpinnerSM from '../../components/Spinner/SpinnerSM';

import DatePicker from "react-datepicker";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';
import dayjs from 'dayjs';

const RoomStateEdit = ({ data, getAllStates }) => {

    // console.log(data);

    const _state = useRef()
    const _ID = useRef()

    const { code, description, end, start, title, uid, id, _id } = data


    const user = useSelector(userSelector)

    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)
    


    const [startDate, setStartDate] = useState(dayjs(start).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs(end).format("YYYY-MM-DD"));

    const busyDates = useRef(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = () => {

        const description = `CHECKIN: ${startDate}\nCHECKOUT: ${endDate}\nNIGHTS: ${new dayjs(endDate).diff(new dayjs(startDate), 'day')}`

        if (_ID.current.value === '') {
            alert('All details are required')
            setEditState(false)

        } else {
            axios.post(target + 'api/cbook/update', {
                id: { _id },
                cbook: {
                    ...data,
                    id: _ID.current.value,
                    title: _state.current.value,
                    start: new Date(startDate),
                    end: new Date(endDate),
                    description
                }
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    console.log(res)
                    handleClose()
                    getAllStates()
                    setEditState(false)
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        window.location.reload()
                    }
                    // console.log(err.response.status);
                    console.log(err)
                    setEditState(false)
                })
        }


    }

    return (
        <div>
            <Button size='sm' onClick={handleShow}>edit</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {code}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">ID</Form.Label>
                        <Form.Control ref={_ID} size="sm" type="number" defaultValue={id} />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">State</Form.Label>
                        <Form.Select ref={_state} aria-label="Default select example" defaultValue={title}>
                            <option value="Booked">Booked</option>
                            <option value="CLOSED - Not available">CLOSED - Not available</option>
                            <option value="External">External</option>
                            <option value="Blocked">Blocked</option>
                        </Form.Select>
                    </Form.Group>

                    <Card>
                        <Card.Body>
                            <div className='edit-date-pickers'>
                                <div>
                                    <p className='edit-date-pickers-title'>Start Date</p>
                                    <DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(dayjs(date).format("YYYY-MM-DD"))} />
                                </div>
                                <div>
                                    <p className='edit-date-pickers-title'>End Date</p>
                                    <DatePicker selected={new Date(endDate)} onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleEdit}> Save Changes <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RoomStateEdit
