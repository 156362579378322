import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import axios from 'axios'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import target from '../../db/target'

const EditAccount = ({ user, getAllAccounts, roleEditable }) => {

    const [typeOfShow, setTypeOfShow] = useState(false)

    const [password, setPassword] = useState('')
    const [updateState, setUpdateState] = useState(false)

    const { _id, username, email, role } = user

    const authUser = useSelector(userSelector)

    const _u = useRef()
    const _p = useRef()
    const _e = useRef()
    const _r = useRef()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handlerRole(e) {
        _r.current.value = e.target.outerText
    }

    function getPassword() {

        console.log('got the password');

        axios.get(target + 'api/account/password', {
            headers: { 'authorization': `bearer ${authUser ? authUser.accessToken : ''}` },
            params: { _id }
        })
            .then(res => {
                setPassword(res.data.password)
            })
            .catch(error => {
                alert(error.message)
            })
    }

    function handleShowWindow() {
        
        getPassword()
        handleShow()
    }

    function handleData() {
        // getPassword()
        setUpdateState(true)


        const _username = _u.current.value
        const _password = _p.current.value
        const _email = _e.current.value
        const _role = _r.current.value

        if (_username === username && _password === password && _email === email && _role === role) {
            setUpdateState(false)
            alert('No changes to update')
        } else if (_username === '' || _password === '' || _email === '' || _role === '') {
            setUpdateState(false)
            alert('Enter all data!')
        } else if (_password.length < 8) {
            setUpdateState(false)
            alert('The length of password must be greater than or equal to 8')
        } else {

            axios.post(target + 'api/account/update', {
                id: {_id},
                user: {
                    username: _username,
                    password: _password,
                    email: _email,
                    role: _role
                }
            },
                {
                    headers: { 'authorization': `bearer ${authUser ? authUser.accessToken : ''}` }
                })
                .then(res => {
                    // console.log(res)
                    handleClose()
                    getAllAccounts()
                    setUpdateState(false)
                    alert(res.data.state)
                })
                .catch(err => {
                    console.log(err)                    
                    setUpdateState(false)
                })
        }

    }


    return (
        <div >
            <Button onClick={handleShowWindow} size='sm'>edit</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit the account</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextUsername">
                            <Form.Label column sm="3">Username</Form.Label>
                            <Col sm="9"><Form.Control ref={_u} type='text' defaultValue={username} placeholder='username' /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="3">Email</Form.Label>
                            <Col sm="9"><Form.Control ref={_e} type='email' defaultValue={email} placeholder='email' /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="3"> Password </Form.Label>
                            <Col sm="9">
                                <InputGroup>
                                    <Form.Control ref={_p} type={typeOfShow ? 'text' : 'password'} defaultValue={password} placeholder='password' />
                                    <Button variant="outline-primary" id="button-addon2" onClick={() => setTypeOfShow(!typeOfShow)}> show </Button>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextRole">
                            <InputGroup>
                                <Form.Label column sm="3" className='me-2'> Role </Form.Label>
                                <Form.Control ref={_r} readonly sm="9" type="text" defaultValue={role} placeholder='role' disabled={roleEditable}/>
                                <DropdownButton title='select'  align="end" id='input-role' disabled={roleEditable}>
                                    <Dropdown.Item onClick={handlerRole}>admin</Dropdown.Item>
                                    <Dropdown.Item onClick={handlerRole}>view</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>  Close </Button>
                    <Button variant="primary" onClick={handleData}>  Save Changes  <SpinnerSM state={updateState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditAccount
