import { useEffect, useReducer, useRef } from 'react';
import './style.css'
import { useState } from 'react';
import axios from 'axios';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import Button from 'react-bootstrap/Button';
import { CloseButton, Form, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import ImageCard from '../../components/ImageCard/ImageCard';
import target from '../../db/target';








const FacilityIcons = () => {

    const user = useSelector(userSelector)
    const authRole = user.user.role === 'admin' ? true : false

    const [allImages, setAllImages] = useState(null)

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState({
        title: 'Uploading Warning',
        message: 'You have not selected any images',
        state: 'warning',
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const images = useRef()
    const [imagePreView, setImagePreView] = useState()
    const [uploadState, setUploadState] = useState(false)

    const reducer = (state, action) => {
        if (action.type === 'deleted') {
            console.log('Dispatch');
            getImages()
            setShowToast(true)
            setShowToastMsg({
                title: 'Deleting Success',
                message: 'Image has been successfully deleted.!',
                state: 'success',
            })
        } else if (action.type === 'error') {
            setShowToast(true)
            setShowToastMsg(action.data)
            getImages()
        }

    }
    const [refresh, dispatch] = useReducer(reducer, { refresh: false })

    const endPoint = target + 'images/icons/'



    function getImages() {

        // console.log(allImages === null || allImages.length === 0);

        console.log('Requested..');

        axios.get(target+'api/images/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            params: {
                dir: 'facility_icons'
            }
        })
            .then(res => {
                // console.log(res.data);
                setAllImages(res.data)
            })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        console.log('REFRESHED');
        getImages()
    }, [refresh])



    function inputImageHandler() {
        setImagePreView(Array.from(images.current.files))
    }

    function uploadFiles() {

        setUploadState(true)

        const file = Array.from(images.current.files)
        const formData = new FormData();

        console.log(file.length);

        if (file.length === 0) {
            setShowToast(true)

            setShowToastMsg({
                title: 'Uploading Warning',
                message: 'You have not selected any images',
                state: 'warning',
            })

            setTimeout(() => {
                setUploadState(false)
            }, 1000);


        } else {
            file.map((file, index) => {
                formData.append("files", file);
            })

            axios.post(target+'api/images/icons', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': `bearer ${user ? user.accessToken : ''}`
                },
            })
                .then(res => {
                    // console.log(res);
                    setUploadState(false)
                    setShow(false)
                    setShowToastMsg({
                        title: 'Uploading Success',
                        message: res.data,
                        state: 'success',
                    })
                    setShowToast(true)
                    getImages()
                })
                .catch(error => {
                    // console.error('ERROR: ', error)
                    setUploadState(false)

                    setShowToastMsg({
                        title: 'Uploading Warning',
                        message: error.message,
                        state: 'danger',
                    })
                    setShowToast(true)
                })
        }

    }


    return (
        <div className='facility-icons'>
            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="">Icons</h3>
                {authRole ? <Button variant="primary" onClick={handleShow}>Upload +</Button> : ''}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Icons</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`image-upload mb-4 ${imagePreView ? 'show' : ''}`}>
                            <div className=' modal-body-edit-img ' style={{ display: 'inline-block' }}>
                                {imagePreView ? imagePreView.map((image, index) => <img key={index} className='image-upload-img' src={URL.createObjectURL(image)} width={150} alt="Images to Upload" />) : ''}
                            </div>
                        </div>
                        <div className="mb-3">
                            <input ref={images} onChange={inputImageHandler} multiple name='files' className="form-control" type="file" accept="image/x-png,image/jpeg" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="primary" onClick={uploadFiles}> Upload <SpinnerSM state={uploadState} /></Button>
                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
                    <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                        <Toast.Header >
                            <strong className="me-auto">{showToastMsg.title}</strong>
                            {/* <small>11 mins ago</small> */}
                        </Toast.Header>
                        <Toast.Body>{showToastMsg.message}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
            <div className="room-images-box">

                {
                    allImages
                        ?
                        allImages.map((fileName, index) => {
                            return <ImageCard key={index} imageURL={endPoint + fileName} fileName={fileName} dispatch={dispatch} size='sm' type='facility_icons' deleteble={authRole}/>
                        })
                        :
                        <SpinnerSM state={true} />
                }

            </div>
        </div>
    )
}

export default FacilityIcons
