import './gallery.css'
import { useEffect, useReducer, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import ImageCard from '../../components/ImageCard/ImageCard';
import target from '../../db/target';
import ImageSelector from '../../components/ImageSelector/ImageSelector';

const Gallery = () => {

    const user = useSelector(userSelector)
    const authRole = user.user.role === 'admin' ? true : false

    const imageReducer = (state, action) => {
        if (action.type === 'gallery-1') {
            console.log(action.images);
            addImages(action.images, 'gallery-1')
            return action.images
        }

        // if (action.type === 'selected-showing') {
        //     console.log(action.images);
        //     addImages(action.images, 'showing')
        //     return action.images
        // }
    }


    const [selectedImages, imageDispatch] = useReducer(imageReducer, [])

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState({
        title: 'Uploading Warning',
        message: 'You have not selected any images',
        state: 'warning',
    });
    const [uploadState, setUploadState] = useState(false)

    const [allImages, setAllImages] = useState(null)

    const reducer = (state, action) => {
        if (action.type === 'deleted') {
            console.log('Dispatch');
            getImages()
            setShowToast(true)
            setShowToastMsg({
                title: 'Deleting Success',
                message: 'Image has been successfully deleted.!',
                state: 'success',
            })
        } else if (action.type === 'error') {
            setShowToast(true)
            setShowToastMsg(action.data)
            getImages()
        }

    }
    const [refresh, dispatch] = useReducer(reducer, { refresh: false })



    const endPoint = target + 'images/rooms/'


    function addImages(images, state) {

        images = images.map((item) => {
            return {
                name: item,
                state
            }
        })


        axios.post(target + 'api/gallery/create', images[0],
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            })
            .then(res => {
                // console.log(res)
                setUploadState(false)
                setShow(false)
                getImages()
                // alert(res)
            })
            .catch(err => {
                console.log(err.message)
                setUploadState(false)
                // alert(err.message)
            })
    }



    function getImages() {

        // console.log(allImages === null || allImages.length === 0);

        console.log('Requested..');

        axios.get(target + 'api/client/gallery/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                console.log(res.data);
                setAllImages(res.data)
            })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        console.log('REFRESHED');
        getImages()
    }, [refresh])



    return (
        <div className='room-images'>
            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="">Gallery</h3>


                {/* <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
            <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                <Toast.Header >
                    <strong className="me-auto">{showToastMsg.title}</strong>
                </Toast.Header>
                <Toast.Body>{showToastMsg.message}</Toast.Body>
            </Toast>
        </ToastContainer> */}


            </div>




            <div className="offers-box">


                <div className="offers-items-box">
                    <div className='offers-titles-box'>
                        {/* <h4>Gellary - 1</h4> */}
                        {authRole ? <ImageSelector dispatch={imageDispatch} imageURL={endPoint} maxselectableImages={1} actionType='gallery-1' /> : ''}

                        <SpinnerSM state={uploadState} />
                    </div>
                    <div className="room-images-box">
                        {
                            allImages
                                ?
                                allImages.map((fileName, index) => {
                                    if (fileName?.name && fileName?.state === 'gallery-1') {
                                        return <ImageCard key={index} imageURL={endPoint + fileName.name} fileName={fileName._id} dispatch={dispatch} deleteble={authRole} deleteurl='api/gallery' />
                                    } else {
                                        return ''
                                    }
                                })
                                :
                                <SpinnerSM state={true} />
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Gallery
