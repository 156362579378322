import React, { useRef, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

import SpinnerSM from '../../components/Spinner/SpinnerSM';

import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import axios from 'axios';
import { nanoid } from 'nanoid';
import target from '../../db/target';

const RoomStateAdd = ({ code, getAllStates }) => {

    const _state = useRef()
    const _id = useRef()
    const id = 222


    const user = useSelector(userSelector)


    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const busyDates = useRef(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAdd = () => {

        // console.log(_id.current.value === '', _state.current.value, startDate, endDate);

        setEditState(true)

        if (_id.current.value === '' || _state.current.value === '' || startDate === '', endDate === '') {
            alert('All details are required')
            setEditState(false)
        } else {
            const cbook = {
                code,
                uid: nanoid(32) + '@heavenuponricefields.com',
                id: _id.current.value,
                title: _state.current.value,
                start: startDate,
                end: endDate
            }

            // console.log(cbook);


            axios.post(target + 'api/cbook/create', cbook,
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                })
                .then(res => {
                    console.log(res)
                    setEditState(false)
                    getAllStates()
                    handleClose()
                })
                .catch(err => {
                    setEditState(false)
                    if (err.response.status) window.location.reload()
                    else alert(err.message)
                })
        }
    }

    return (
        <div>
            <Button size='sm' onClick={handleShow}>add</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a state</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">ID</Form.Label>
                        <Form.Control ref={_id} size="sm" type="number" defaultValue={id} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">State</Form.Label>
                        <Form.Select ref={_state} aria-label="Default select example">
                            {/* <option value="BOOKED">Booked</option>
                            <option value="PENDING">Pending</option>
                            <option value="EXTERNAL">External</option>
                            <option value="BLOCKED">Blocked</option> */}
                            <option value="Booked">Booked</option>
                            <option value="Pending">Pending</option>
                            <option value="CLOSED - Not available">CLOSED - Not available</option>
                            <option value="Blocked">Blocked</option>
                        </Form.Select>
                    </Form.Group>

                    <Card>
                        <Card.Body>
                            <div className='edit-date-pickers'>
                                <div>
                                    <p className='edit-date-pickers-title'>Start Date</p>
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <div>
                                    <p className='edit-date-pickers-title'>End Date</p>
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleAdd} > Add <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RoomStateAdd
