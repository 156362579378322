import React from 'react'

const SpinnerSM = ({ state }) => {
    return (
        <>
            {state 
            ? 
            <div className="ms-2 spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            : 
            ''
            }
        </>
    )
}

export default SpinnerSM
