import axios from 'axios';
import React, { createRef, useRef, useState } from 'react'
import { Button, Card, Form, Modal, Table, Toast, ToastContainer } from 'react-bootstrap';
import { userSelector } from '../../../store/reducers/user';
import { useSelector } from 'react-redux';
import SpinnerSM from '../../../components/Spinner/SpinnerSM';
import target from '../../../db/target';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';



const EditSeasonPrice = ({ data, getAllSeason, _default }) => {

    const user = useSelector(userSelector)

    const [show, setShow] = useState(false);
    const [addState, setAddState] = useState(false)
    const [showToast, setShowToast] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState({
        title: 'Form',
        message: 'All * details are required',
        state: 'warning',
    });

    const [dataObj, setDataObj] = useState(data)
    const [startDate, setStartDate] = useState(dataObj.start);
    const [endDate, setEndDate] = useState(dataObj.end);



    const _title = createRef()

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };


    const change = (data) => {

        const newDataArray = dataObj.prices.map(item => {

            if (item.code === data.code) {
                return {
                    code: item.code,
                    prices: item.prices.map(pkg => {
                        if (pkg.title === data.title) {
                            return {
                                ...pkg,
                                price: parseInt(data.value)
                            }
                        } else {
                            return pkg
                        }
                    })
                }
            } else {
                return item
            }

        })

        setDataObj({
            ...dataObj,
            prices: newDataArray
        })
    }


    const handleEdit = () => {

        setAddState(true)
        if (_title.current.value === '') {
            setAddState(false)
            setShowToastMsg({
                title: 'Form Warning',
                message: 'All * details are required',
                state: 'warning',
            })
            setShowToast(true)
        } else {
            const season = {
                title: _title.current.value,
                start: dayjs(startDate).format("YYYY-MM-DD"),
                end: dayjs(endDate).format("YYYY-MM-DD"),
                prices: dataObj.prices
            }

            axios.post(target + 'api/season/update', {
                id: data._id,
                season
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    setShowToastMsg({
                        title: 'Information',
                        message: 'Updated the season sucsesfully',
                        state: 'info',
                    })
                    getAllSeason()
                    setShowToast(true)
                    handleClose()
                    setAddState(false)
                })
                .catch(err => {

                    console.log(err)
                    setAddState(false)
                    setShowToastMsg({
                        title: 'Erorr',
                        message: err.message,
                        state: 'danger',
                    })
                    setShowToast(true)
                    if (err.response.status === 403) {
                        window.location.reload()
                    }
                })
        }

    }


    return <div>
        <Button variant="primary" size='sm' onClick={handleShow}>edit</Button>
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Edit the package</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                        <Form.Label column="sm">Title *</Form.Label>
                        <Form.Control ref={_title} size="sm" type="text" defaultValue={dataObj.title} readOnly={_default} />
                    </Form.Group>
                    {
                        _default ? '' : <Card>
                            <Card.Body>
                                <div className='edit-date-pickers'>
                                    <div>
                                        <p className='edit-date-pickers-title'>Start Date</p>
                                        <DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                    <div>
                                        <p className='edit-date-pickers-title'>End Date</p>
                                        <DatePicker selected={new Date(endDate)} onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    }

                    <Card style={{ marginTop: '20px' }}>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr style={{ fontSize: '12px' }}>
                                        <th>code</th>
                                        {
                                            dataObj.prices[0].prices.map((packageprice, index) => {
                                                return <th key={index}>{packageprice.title}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        dataObj.prices.map((item, index) => {
                                            return <tr key={index} style={{ fontSize: '14px' }} >
                                                <td >{item.code}</td>
                                                {
                                                    item.prices.map((packageItem, index) => {
                                                        return <td key={index}>
                                                            <Form.Group className="" controlId="formGroupTitle" style={{ width: '70px' }}>
                                                                <Form.Control size="sm" type="number" defaultValue={packageItem.price} onChange={evt => change({ code: item.code, title: packageItem.title, value: evt.target.value })} />
                                                            </Form.Group>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
                <Button variant="primary" onClick={handleEdit}>  Save Changes  <SpinnerSM state={addState} />  </Button>
            </Modal.Footer>
        </Modal>




        <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
            <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                <Toast.Header >
                    <strong className="me-auto">{showToastMsg.title}</strong>
                </Toast.Header>
                <Toast.Body>{showToastMsg.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    </div>
}

export default EditSeasonPrice
