import React, { useRef, useState } from 'react'
import { Badge, Button, Form, Modal, Stack } from 'react-bootstrap'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useReducer } from 'react'

import ImageSelector from '../../components/ImageSelector/ImageSelector'
import './style.css'
import Spinner from '../../components/Spinner/Spinner'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { userSelector } from '../../store/reducers/user';
import FacilitySelector from '../../components/FacilitySelector/FacilitySelector';
import target from '../../db/target';


const BadgeCard = ({ facility, dispatch }) => {

    // console.log("-----------", facility.title);

    const [errorMsgState, setErrorMsgState] = useState(false)
    const [deleteState, setDeleteState] = useState(false)
    const deleteComform = useRef()
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dataHandler = () => {

        dispatch({
            type: 'change-state',
            title: facility.title
        })

        handleClose()
    }

    return <>
        <Badge pill style={{ marginRight: '5px', marginBottom: '2px', cursor: 'pointer' }} onClick={handleShow}>{facility.title}  </Badge>

        <Modal size='sm' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <label htmlFor="exampleFormControlInput1" className="form-label delete-text ">write "<span>DELETE</span>" to delete</label>
                <input ref={deleteComform} type="test" className="form-control" id="exampleFormControlInput1" />
                {errorMsgState ? <h6 style={{ color: 'red', fontSize: '11px', paddingLeft: '2px', paddingTop: '2px' }}>Enter the word correct</h6> : ''}
            </Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant="secondary" onClick={handleClose}>Close</Button>
                <Button size='sm' variant="danger" onClick={dataHandler}> Delete <SpinnerSM state={deleteState} /></Button>
            </Modal.Footer>
        </Modal>
    </>
}




const AddRoom = ({ endPoint, group, refresh }) => {

    const imageReducer = (state, action) => {
        if (action.type === 'selected') {
            return action.images
        } else if (action.type === 'clear') {
            return []
        }
    }
    const facilityReducer = (state, action) => {
        if (action.type === 'change') {
            return action.facilities.filter(facility => {
                return facility.state === true
            })
        } else if (action.type === 'change-state') {
            return state.filter(facility => {
                return facility.title != action.title
            })
        } else if (action.type === 'clear') {
            return []
        }
    }

    const [selectedImages, imageDispatch] = useReducer(imageReducer, [])
    const [selectedFacilities, facilityDispatch] = useReducer(facilityReducer, [])


    const user = useSelector(userSelector)
    const [uploadState, setuploadState] = useState(false)

    const [show, setShow] = useState(false);


    // input group
    const _code = useRef();
    const _title = useRef();
    const _description = useRef();

    const handleShow = () => {
        imageDispatch({ type: 'clear' })
        facilityDispatch({ type: 'clear' })
        setShow(true)
    }

    const handleClose = () => setShow(false)

    function dataHandler() {

        setuploadState(true)

        // console.log('---> ', _code.current.value, _title.current.value, _description.current.value, selectedImages.length, selectedFacilities.length);

        if (_code.current.value === '' || _title.current.value === '' || selectedImages.length <= 0 || selectedFacilities.length <= 0) {


            
            setuploadState(false)
            alert('All details are required')
        } else if (selectedImages.length <= 3) {
            setuploadState(false)
            alert('4 images are required')
        } else {
            const room = {
                group,
                code: _code.current.value,
                title: _title.current.value,
                description: _description.current.value,
                images: selectedImages,
                facilities: selectedFacilities
            }

            

            axios.post(target + 'api/room/create', room,
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                })
                .then(res => {
                    // console.log(res)
                    setuploadState(false)
                    setShow(false)
                    refresh()
                    // alert(res)
                })
                .catch(err => {
                    console.log(err.message)
                    setuploadState(false)
                    // alert(err.message)
                })

        }
    }


    return (
        <div>
            <Button size='sm' variant="primary" className="btn  btn-primary  ms-4" onClick={handleShow}>+ add room</Button>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add a room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupCode">
                            <Form.Label column="sm">Room Code</Form.Label>
                            <Form.Control ref={_code} size="sm" type="number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">Room Title</Form.Label>
                            <Form.Control ref={_title} size="sm" type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label column="sm">Description</Form.Label>
                            <Form.Control ref={_description} as="textarea" rows={3} size="sm" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formImages">
                            <Form.Label column="sm">Images</Form.Label>
                            <div className="room-images-box img-thumbnail" id='images-display'>
                                {
                                    selectedImages.map((img, index) => {
                                        return <img key={index} src={endPoint + img} width={180} className="room-imgs m-1  d-inline" alt="..." />
                                    })
                                }
                                <ImageSelector dispatch={imageDispatch} imageURL={endPoint} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlFacilitis" >
                            <Form.Label column="sm">Facilities</Form.Label>
                            <div className="room-images-box img-thumbnail" id='images-display'>
                                <Stack direction="horizontal" gap={2} style={{ display: 'inline-block' }} >
                                    {
                                        selectedFacilities.map((facility, index) => {
                                            return <BadgeCard facility={facility} key={index} dispatch={facilityDispatch}/>
                                        })
                                    }
                                </Stack>
                                <FacilitySelector dispatch={facilityDispatch} facilities={selectedFacilities} edit='add'/>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={dataHandler} > Add Room <SpinnerSM state={uploadState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddRoom