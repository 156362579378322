import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import target from '../../db/target'


const OldPaymentCard = ({ data, getAllPaidlist }) => {

    return <tr>
        <th style={{ fontWeight: '400' }}>#{data.id}</th>
        <th style={{ fontWeight: '400' }}>{data.title}</th>
        <th style={{ fontWeight: '400' }}>{data.price}</th>
        <th style={{ fontWeight: '400' }}>{data.description || '-'}</th>
    </tr>
}


const OldPayment = () => {

    const [refresh, setRefresh] = useState()
    const [paidlist, setPaidlist] = useState([])
    const [show, showAll] = useState(true)

    const user = useSelector(userSelector)


    function getAllPaidlist() {

        setRefresh(true)

        axios.get(target + 'api/payment/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            // console.log(res.data);
            setPaidlist(res.data)
            setRefresh(false)
        })
            .catch(error => {
                console.log(error)
                setRefresh(false)
            })

    }

    useEffect(() => {
        getAllPaidlist()
    }, [])

    return (
        <div className='room-price-card' style={{ marginBottom: '50px' }}>
            <h6 className="title">Paid List <Button size='sm' style={{ padding: '2px 5px', marginLeft: '10px' }} onClick={() => showAll(!show)} >{show ? 'hide' : 'show'}</Button> </h6>

            {
                show ?
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <Button size='sm' onClick={getAllPaidlist} >Refresh<SpinnerSM state={refresh} /></Button>
                            {/* {authRole ? <DiscountAndTaxAdd getAllDiscouts={getAllDiscouts} /> : ''} */}

                        </div>

                        <Table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>title</th>
                                    <th>price</th>
                                    <th>description</th>

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    paidlist.map((data, index) => {
                                        return <OldPaymentCard data={data} key={index} getAllPaidlist={getAllPaidlist} />
                                    })
                                }

                            </tbody>
                        </Table>
                    </div>
                    :
                    ""
            }


        </div>
    )
}

export default OldPayment
