
import axios from 'axios'
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import ImageSelector from '../../components/ImageSelector/ImageSelector'
import Spinner from '../../components/Spinner/Spinner'
import AddRoom from './AddRoom'
import EditRoom from './EditRoom'
import ViewRoom from './ViewRoom'
import './style.css'



import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import target from "../../db/target";

// const room = {
//   code: 302,
//   title: "Deluxe Double Room with Balcony & Rice Paddy View",
//   description: ".h1 through .h6 classes are also available, for when you want to match the font styling of a heading but cannot use the associated HTML element.",
//   images: ['1689643306221.jpeg', '1689643306263.png', '1689643837782.jpeg', '1689643856054.jpeg'],
//   facilities: [
//     {
//       icon: "2342345523.png",
//       title: "Free WiFi",
//       state: true
//     },
//     {
//       icon: "3242342343.png",
//       title: "Air Conditioning",
//       state: false
//     },
//     {
//       icon: "3354352343.png",
//       title: "Something Else",
//       state: true
//     }
//   ],
//   packageSelect: [
//     {
//       packageTitle: "Room Only",
//       packageDiscription: "Not included Breakfast or meals",
//       packagePrice: 20,
//     },
//     {
//       packageTitle: "Bed & Breakfast",
//       packageDiscription: "Breakfast included",
//       packagePrice: 25,
//     },
//     {
//       packageTitle: "Half Board",
//       packageDiscription: "Included two meals a day",
//       packagePrice: 30,
//     },
//   ]
// }

const Room = ({ room, endPoint, getRooms, authRole }) => {

  const { _id, group, code, title } = room;

  function refresh() {
    getRooms(group)
  }

  return <div className="room border rounded p-2 mb-2 ms-4" >
    <h5>{code}</h5>
    <h6 className='' style={{ fontSize: '14px', fontWeight: 400, fontStyle: 'italic' }}>{title}</h6>
    <div className='room-btn'>
      {/* <button type="button" className="btn btn-primary  btn-sm" data-bs-toggle="modal" data-bs-target="#view-room" onClick={() => console.log(room)}>view</button> */}
      {/* <button type="button" className="btn btn-primary align-m  btn-sm" data-bs-toggle="modal" data-bs-target="#edit-room">Edit</button> */}

      <ViewRoom room={room} endPoint={endPoint} />
      {authRole ? <EditRoom room={room} endPoint={endPoint} getRooms={getRooms} /> : ''}
      {authRole ? <DeleteItem code='DELETE' title={title} endpoint={target + 'api/room/'} params={{ _id }} refresh={refresh} /> : ''}

    </div>
  </div>
}


export const Rooms = () => {

  const endPoint = target + 'images/rooms/'

  const user = useSelector(userSelector)
  const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

  const [rooms_0, setRooms_0] = useState([])
  const [rooms_1, setRooms_1] = useState([])
  const [rooms_2, setRooms_2] = useState([])

  function getRooms(group) {

    axios.get(target + 'api/room/all', {
      headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
      params: { group }
    })
      .then(res => {
        if (group === 0) setRooms_0(res.data)
        else if (group === 1) setRooms_1(res.data)
        else if (group === 2) setRooms_2(res.data)
      })
      .catch(error => {
        console.log(error.message);
      })
  }


  function refresh() {
    getRooms(0)
    getRooms(1)
    getRooms(2)
  }

  useEffect(() => {
    refresh()
  }, [])


  return (
    <div className='rooms ' >
      <h3 className="title">Rooms</h3>
      <div className="room-list">
        <div className="room-group border p-4 rounded">
          <h5 className='title mb-4'> Group #1 : Deluxe Double Room with Balcony & Rice Paddy View</h5>
          {
            rooms_0.length > 0
              ?
              rooms_0.map((room, index) => {
                return <Room authRole={authRole} key={index} room={room} endPoint={endPoint} getRooms={getRooms} />
              })
              :
              <Spinner state={true} />
          }
          {authRole ? <AddRoom refresh={refresh} endPoint={endPoint} group={0} /> : ''}

        </div>
        <div className="room-group border p-4 rounded mt-4 ">
          <h5 className='title mb-4'> Group #2 : Deluxe Double Room With Balcony</h5>
          {
            rooms_1.length > 0
              ?
              rooms_1.map((room, index) => {
                return <Room authRole={authRole} key={index} room={room} endPoint={endPoint} getRooms={getRooms} />
              })
              :
              <Spinner state={true} />
          }
          {authRole ? <AddRoom refresh={refresh} endPoint={endPoint} group={1} /> : ''}
        </div>
        <div className="room-group border p-4 rounded mt-4">
          <h5 className='title mb-4'> Group #3 : Deluxe Double Room With Patio & Rice Paddy View</h5>
          {
            rooms_2.length > 0
              ?
              rooms_2.map((room, index) => {
                return <Room authRole={authRole} key={index} room={room} endPoint={endPoint} getRooms={getRooms} />
              })
              :
              <Spinner state={true} />
          }

          {authRole ? <AddRoom refresh={refresh} endPoint={endPoint} group={2} /> : ''}
        </div>
      </div>
    </div>
  )

}
