import { useEffect, useReducer, useRef } from 'react';
import './style.css'
import { useState } from 'react';
import axios from 'axios';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import ImageCard from '../../components/ImageCard/ImageCard';
import target from '../../db/target';
import ImageSelector from '../../components/ImageSelector/ImageSelector';








const Offers = () => {

    const user = useSelector(userSelector)
    const authRole = user.user.role === 'admin' ? true : false


    const imageReducer = (state, action) => {
        if (action.type === 'selected-loading') {
            console.log(action.images);
            addImages(action.images, 'loading')
            return action.images
        }

        if (action.type === 'selected-showing') {
            console.log(action.images);
            addImages(action.images, 'showing')
            return action.images
        }
    }

    const [selectedImages, imageDispatch] = useReducer(imageReducer, [])

    const [allImages, setAllImages] = useState(null)

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState({
        title: 'Uploading Warning',
        message: 'You have not selected any images',
        state: 'warning',
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [uploadState, setUploadState] = useState(false)

    const reducer = (state, action) => {
        if (action.type === 'deleted') {
            console.log('Dispatch');
            getImages()
            setShowToast(true)
            setShowToastMsg({
                title: 'Deleting Success',
                message: 'Image has been successfully deleted.!',
                state: 'success',
            })
        } else if (action.type === 'error') {
            setShowToast(true)
            setShowToastMsg(action.data)
            getImages()
        }

    }
    const [refresh, dispatch] = useReducer(reducer, { refresh: false })

    const endPoint = target + 'images/rooms/'


    function addImages(images, state) {

        images = images.map((item) => {
            return {
                name: item,
                state
            }
        })


        axios.post(target + 'api/offers/create', images[0],
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            })
            .then(res => {
                // console.log(res)
                setUploadState(false)
                setShow(false)
                getImages()
                // alert(res)
            })
            .catch(err => {
                console.log(err.message)
                setUploadState(false)
                // alert(err.message)
            })
    }



    function getImages() {

        // console.log(allImages === null || allImages.length === 0);

        console.log('Requested..');

        axios.get(target + 'api/client/offers/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                console.log(res.data);
                setAllImages(res.data)
            })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        console.log('REFRESHED');
        getImages()
    }, [refresh])




    return (
        <div className='room-images'>
            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="">Offers</h3>


                {/* <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
                    <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                        <Toast.Header >
                            <strong className="me-auto">{showToastMsg.title}</strong>
                        </Toast.Header>
                        <Toast.Body>{showToastMsg.message}</Toast.Body>
                    </Toast>
                </ToastContainer> */}


            </div>




            <div className="offers-box">


                <div className="offers-items-box">
                    <div className='offers-titles-box'>
                        <h4>Loading Window</h4>
                        {authRole ? <ImageSelector dispatch={imageDispatch} imageURL={endPoint} maxselectableImages={1} actionType='selected-loading' /> : ''}
                        
                        <SpinnerSM state={uploadState} />
                    </div>
                    <div className="room-images-box">
                        {
                            allImages
                                ?
                                allImages.map((fileName, index) => {
                                    if (fileName?.name && fileName?.state === 'loading') {
                                        return <ImageCard key={index} imageURL={endPoint + fileName.name} fileName={fileName._id} dispatch={dispatch} deleteble={authRole} deleteurl='api/offers' />
                                    } else {
                                        return ''
                                    }
                                })
                                :
                                <SpinnerSM state={true} />
                        }
                    </div>
                </div>


                <hr />

                <div className="offers-items-box">
                    <div className='offers-titles-box'>
                    <h4>Showing Window</h4>
                     {authRole  ? <ImageSelector dispatch={imageDispatch} imageURL={endPoint} maxselectableImages={1} actionType='selected-showing' /> : ''}
                    
                    </div>
                    <div className="room-images-box">
                        {
                            allImages
                                ?
                                allImages.map((fileName, index) => {
                                    if (fileName?.name && fileName?.state === 'showing') {
                                        return <ImageCard key={index} imageURL={endPoint + fileName.name} fileName={fileName._id} dispatch={dispatch} deleteble={authRole} deleteurl='api/offers' />
                                    } else {
                                        return ''
                                    }
                                })
                                :
                                <SpinnerSM state={true} />
                        }
                    </div>
                </div>



            </div>

        </div>
    )
}

export default Offers
