import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import target from '../../db/target';


const DeleteAccount = ({ username, id, getAllAccounts , deleteable}) => {

    const user = useSelector(userSelector)

    const [errorMsgState, setErrorMsgState] = useState(false)

    const [deleteState, setDeleteState] = useState(false)
    const deleteComform = useRef()
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dataHandler = () => {
        if (deleteComform.current.value !== username) {
            setErrorMsgState(true)
            setTimeout(() => setErrorMsgState(false), 3000);

        } else {
            setErrorMsgState(false)
            
            setDeleteState(true)

            axios.delete(target + 'api/account', {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                params: {id}
            })
                .then(res => {
                    if (res.status === 202) {
                        // console.log('DELETE-OK: ', res);
                        setDeleteState(false)
                        setShow(false)
                        getAllAccounts()
                    }

                })
                .catch(error => {
                    // console.error('DELETE-ERROR: ', error)
                    if(error?.response) alert(error.response.data)
                    else alert(error.message)
                    setDeleteState(false)
                })
        }
    }

    return (
        <div>
            <Button variant="danger" size='sm' onClick={handleShow} disabled={deleteable}>delete</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete a account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="exampleFormControlInput1" className="form-label delete-text ">write "<span>{username}</span>" to delete</label>
                    <input ref={deleteComform} type="test" className="form-control" id="exampleFormControlInput1" />
                    {errorMsgState ? <h6 style={{ color: 'red', fontSize: '11px', paddingLeft: '2px', paddingTop: '2px' }}>Enter the word correct</h6> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="danger" onClick={dataHandler}> Delete <SpinnerSM state={deleteState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteAccount
