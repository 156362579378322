import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { SideBar } from "./components/SideBar/SideBar";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Rooms } from "./pages/Rooms/Rooms";
import { CalendarView } from "./pages/CalendarView/CalendarView";
import Facilities from "./pages/Facilities/Facilities";
import RoomImages from "./pages/RoomImages/RoomImages";
import FacilityIcons from "./pages/FacilityIcons/FacilityIcons";
import Login from "./pages/Login/Login";
import { Provider } from "react-redux";
import store from "./store/store";
import { Price } from "./pages/Price/Price";
import { Accounts } from "./pages/Accounts/Accounts";
import Offers from "./pages/Offers/Offers";
import Gallery from "./pages/Gallery/Gallery";
import Payment from "./pages/Payment/Payment";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <SideBar />

        <Routes>
          <Route index element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/calendar" element={<CalendarView />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/price" element={<Price />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/roomimages" element={<RoomImages />} />
          <Route path="/facilityicons" element={<FacilityIcons />} />
          <Route path="/accounts" element={<Accounts />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
