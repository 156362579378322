import React, { useReducer, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';

const EditFasility = ({ item, index, getAllFacilities }) => {

    const user = useSelector(userSelector)
    // const authUser = user !== null ? (user.user.role === 'admin' ? true : false) : false

    const { _id, title, iconUrl } = item
    const endPoint = target + 'images/icons/'

    // const [iconUrl, setIconUrl] = useState(_iconUrl)

    function reducer(state, action) {
        if (action.type === 'selected') {
            // console.log(action.images);
            return action.images[0]
        } else if (action.type === 'restart') {
            return iconUrl
        }
    }
    const [_iconUrl, imageDispatch] = useReducer(reducer, iconUrl)
    const [_title, setTitle] = useState(title)


    const [updateState, setUpdateState] = useState(false)
    const [show, setShow] = useState(false);

    const handleShow = () => {
        imageDispatch({ type: 'restart' })
        setShow(true)
    }
    const handleClose = () => setShow(false)


    const dataHandler = () => {

        setUpdateState(true)

        console.log(title, _title, iconUrl, _iconUrl);

        if (title === _title && iconUrl === _iconUrl) {
            console.log('No update');
        } else {
            axios.post(target + 'api/facility/update', {
                id: { _id },
                facility: {
                    title: _title,
                    iconUrl: _iconUrl
                }
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    // console.log(res)
                    handleClose()
                    getAllFacilities()
                    setUpdateState(false)
                })
                .catch(err => {
                    console.log(err)
                    setUpdateState(false)
                })
            // // update code
            // setTimeout(() => {
            //     // dispatch({
            //     //     type: 'changed',
            //     // })

            // setUpdateState(false)
            // handleClose()

            // }, 1000);
        }
    }

    return (
        <div>
            <Button variant="primary" size='sm' onClick={handleShow}>edit</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit the facility</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=' modal-body-edit-img '>
                        <img src={endPoint + _iconUrl} width={80} alt="Facility Avator" />
                        <ImageSelector imageURL={endPoint} dirType='facility_icons' maxselectableImages={1} dispatch={imageDispatch} />
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="inputTitle" className="col-sm-2 col-form-label edit-text">Title</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="inputTitle" defaultValue={_title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={dataHandler}> Update Changes <SpinnerSM state={updateState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditFasility
