
import { useEffect, useReducer, useState } from 'react'
import './style.css'
import Spinner from '../../components/Spinner/Spinner'
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import EditFacility from './EditFacility'
import AddFacility from './AddFacility.jsx'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import target from '../../db/target'

// const facilities = [
//     {
//         title: 'title1das',
//         iconUrl: '1689643373493.png',
//     },
//     {
//         title: 'title2',
//         iconUrl: '1689643373493.png',
//     },
//     {
//         title: 'title3',
//         iconUrl: '1689643373493.png',
//     },
// ]



const imageUrl = '1689643373493.png'
// const _title = 'FREE WIFI'


const Facility = ({ item, index, authRole, getAllFacilities }) => {


    const endPoint = target + 'images/icons/'

    const { _id, title, iconUrl } = item



    return <li className="list-group-item d-flex justify-content-between align-items-center">
        <div>
            <img src={endPoint + iconUrl} width={30} alt="" id='thirdRadio' />
            <label className="form-check-label ms-4" >{title}</label>
        </div>
        <div className="_btn-facility badge d-flex">
            {authRole ? <EditFacility item={item} getAllFacilities={getAllFacilities} /> : ''}
            {authRole ? <DeleteItem code='DELETE' title={title} endpoint={target + 'api/facility'} params={{ _id }} refresh={getAllFacilities} /> : ''}
        </div>
    </li>
}




const Facilities = () => {


    const user = useSelector(userSelector)

    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false


    // function reducer(state, action) {
    //     if (action.type === 'changed') {
    //         console.log('Changed & Refresh');            
    //     }else  if (action.type === 'added') {
    //         console.log('added & Refresh');            
    //     }
    //     return state
    // }

    // const [facilities, facilitiesDispatch] = useReducer(reducer, _facilities)

    const [facilities, setFacilities] = useState([])

    const [loadState, setLoadState] = useState(false)

    const getAllFacilities = () => {

        setLoadState(true)

        axios.get(target + 'api/facility/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                // console.log(res.data);

                setFacilities(res.data)
                setLoadState(false)
            })
            .catch(error => {
                if (error.response.status) window.location.reload()
                else alert(error.message)

                setLoadState(false)
            })

    }

    useEffect(() => {
        getAllFacilities()
    }, [])



    // console.log(facilities);


    return (
        <div className='facilities'>

            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="">Facilities</h3>
                {authRole ? <AddFacility getAllFacilities={getAllFacilities} /> : ''}
            </div>

            {facilities.length === 0
                ?
                <Spinner state={loadState} />
                :
                <ul className="list-group">
                    {
                        facilities.map((item, index) => {
                            return <Facility key={index} item={item} authRole={authRole} index={index} getAllFacilities={getAllFacilities} />
                        })
                    }
                </ul>
            }
        </div>
    )
}

export default Facilities
