import React, { useEffect, useRef, useState } from 'react'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import axios from 'axios'
import { nanoid } from 'nanoid'
import target from '../../db/target'


const EditCalenderLink = ({ user, curl, getAllUrls }) => {


    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const _import = useRef();
    const _export = useRef();

    function handleEdit() {
        setEditState(true)


        if (_import.current.value === curl.import && _export.current.value === curl.export) {
            console.log('No update');
            alert('No update')
            setEditState(false)
        } else {
            axios.post(target + 'api/curl/update', {
                id: { _id: curl._id },
                curl: {
                    code: curl.code,
                    import: _import.current.value,
                    export: _export.current.value,
                }
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    console.log(res)
                    handleClose()
                    getAllUrls()
                    setEditState(false)
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        window.location.reload()
                    }
                    // console.log(err.response.status);
                    console.log(err)
                    setEditState(false)
                })
        }
    }



    return <div>
        <Button size='sm' style={{ float: 'right' }} onClick={handleShow}>edit</Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit : {curl.code} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">Import Url</Form.Label>
                        <Form.Control ref={_import} size="sm" type="text" defaultValue={curl.import} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupCode1">
                        <Form.Label column="sm">Export Url</Form.Label>
                        <Form.Control readOnly ref={_export} size="sm" type="text" defaultValue={curl.export} />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
                <Button variant="primary" onClick={handleEdit}> Save Changes <SpinnerSM state={editState} /> </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

const CalendarLink = ({ authRole }) => {

    const [curls, setCrls] = useState([])
    const [refresh, setRefresh] = useState(false)
    const user = useSelector(userSelector)

    function getAllUrls() {

        setRefresh(true)

        axios.get(target + 'api/curl/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            // console.log(res);
            setCrls(res.data)

            // console.log(res.data);
            setRefresh(false)
        })
            .catch(error => {
                console.log(error)
                setRefresh(false)
            })



    }

    useEffect(() => {
        getAllUrls()
    }, [])

    return (
        <div style={{ paddingBottom: '50px' }}>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                <Button size='sm' onClick={getAllUrls} >Refresh<SpinnerSM state={refresh} /></Button>
                <Table>
                    <thead>
                        <tr>
                            <th>code</th>
                            <th>import</th>
                            <th>export</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            curls.map((curl, index) => {
                                return <tr key={index}>
                                    <th style={{ fontWeight: '400' }}>{curl.code}</th>
                                    <th style={{ fontWeight: '400' }}>{curl.import ? curl.import : '-'}</th>
                                    <th style={{ fontWeight: '400' }}>{curl.export ? curl.export : '-'}</th>
                                    <th style={{ fontWeight: '400' }}>
                                        {
                                            authRole ? <EditCalenderLink user={user} curl={curl} getAllUrls={getAllUrls} /> : ''
                                        }
                                    </th>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default CalendarLink
