import { Badge, Button, Card, Form, InputGroup, ListGroup, Modal, Table } from 'react-bootstrap'
import './style.css'
import { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import EditPrice from './EditPrice';
import ViewPrice from './ViewPrice';
import AddPrice from './AddPrice';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import CalendarLink from './CalendarLink';
import DiscountAndTax from './DiscountAndTax';
import target from '../../db/target';
import SeasonPrice from './season/SeasonPrice';



const RoomPackagesPriceCard1 = ({ packageprice, authRole, getAllPackagePrices }) => {
    const { _id, id, title = 'Room Only', price, description, oneperson, onechild } = packageprice

    return <tr>
        <td>{id}</td>
        <td>{title}</td>
        <td>{price}$</td>
        <td>{oneperson <= 0 ? '-' : oneperson + ' $'}</td>
        <td>{onechild <= 0 ? '-' : onechild + ' $'}</td>
        <td>
            <div style={{ float: 'right', display: 'flex', gap: '20px' }}>
                <ViewPrice packageprice={packageprice} />
                {authRole ? <EditPrice packageprice={packageprice} getAllPackagePrices={getAllPackagePrices} /> : ''}
                {/* <DeleteItem code='DELETE' title={title} endpoint={`${target}api/packageprice/`} params={{ _id }} refresh={getAllPackagePrices} /> */}
            </div>
        </td>
    </tr>
}

const RoomPriceEdit = ({ authRole, roomprice, getAllRoomPrices }) => {


    const { _id, code, prices = [] } = roomprice
    const _prices = prices

    const user = useSelector(userSelector)



    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());

    // const busyDates = useRef(false)

    const [show, setShow] = useState(false);

    const handleEdit = () => {

        axios.post(target + 'api/roomprice/update', {
            id: { _id },
            roomprice: {
                prices: _prices,
                // state: busyDates.current.checked,
                // start: startDate,
                // end: endDate
            }
        },
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
            })
            .then(res => {
                handleClose()
                getAllRoomPrices()

            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // console.log(authRole);
        if (authRole) setShow(true)
    };



    const priceChangeHandle = (index, value) => {
        _prices[index].price = value
    }

    return <div style={{ float: 'right' }} >
        <Button size='sm' onClick={handleShow}>edit</Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    {
                        prices.map((item, index) => {
                            return <Form.Group key={index} className="mb-3" controlId="formGroupCode">
                                <Form.Label column="sm">{item.title}</Form.Label>
                                <Form.Control size="sm" type="number" defaultValue={item.price} onChange={(e) => {
                                    priceChangeHandle(index, parseInt(e.target.value))
                                }} />
                            </Form.Group>
                        })
                    }
                </Form>

                {/* <Card>
                    <Card.Header><Form.Check type='switch' id='check-for-date' ref={busyDates} defaultChecked={state} /></Card.Header>
                    <Card.Body>

                        <div className='edit-date-pickers'>
                            <div>
                                <p className='edit-date-pickers-title'>Start Date</p>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div>
                                <p className='edit-date-pickers-title'>End Date</p>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        </div>
                    </Card.Body>
                </Card> */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close  </Button>
                <Button variant="primary" onClick={handleEdit}> Save Changes </Button>
            </Modal.Footer>
        </Modal>

    </div>

}




const RoomPriceCard = ({ roomprice, getAllRoomPrices, authRole }) => {

    const { _id, code, price = 0, prices = [], state, start, end } = roomprice

    // console.log(prices);

    const user = useSelector(userSelector)


    return <tr style={{ margin: '5px' }} >
        <th style={{ fontWeight: '400' }}>{code}</th>
        {
            prices.map((item, index) => {
                return <th style={{ fontWeight: '400' }} key={index} >{item.price} $</th>
            })
        }
        {/* <th style={{ fontWeight: '400' }}>{state ? 'closed' : '-'}</th>
        <th style={{ fontWeight: '400' }}>{state ? new Date(start).toLocaleDateString() : '-'}</th>
        <th style={{ fontWeight: '400' }}>{state ? new Date(end).toLocaleDateString() : '-'}</th> */}
        {authRole ? <RoomPriceEdit authRole={authRole} roomprice={roomprice} getAllRoomPrices={getAllRoomPrices} /> : ''}
    </tr>
}


export const Price = () => {

    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    const [roomprices, setRoomPrices] = useState([])
    const [packageprices, setPackagePrices] = useState([])
    const [refresh, setRefresh] = useState(false)

    function getAllPackagePrices() {
        axios.get(target + 'api/packageprice/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            setPackagePrices(res.data)
        })
            .catch(error => {
                console.log(error)
            })
    }


    function getAllRoomPrices() {

        setRefresh(true)

        axios.get(target + 'api/roomprice/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            setRoomPrices(res.data)

            setRefresh(false)
        })
            .catch(error => {
                console.log(error)
                setRefresh(false)
            })

    }

    useEffect(() => {
        getAllPackagePrices()
        getAllRoomPrices()
    }, [])

    return (
        <div className='calendarview'>
            <h3 className="title">Price</h3>

            <hr />

            <DiscountAndTax />

            <hr />
            <div className="room-packages-price-box" style={{ marginBottom: '50px' }}>
                <h6 className="title"> Packages</h6>

                <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Button size='sm' onClick={getAllRoomPrices} >Refresh<SpinnerSM state={refresh} /></Button>
                        {/* {authRole ? <AddPrice getAllPackagePrices={getAllPackagePrices} /> : ''} */}
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>title</th>
                                <th>price</th>
                                <th>oneperson</th>
                                <th>onechild</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                packageprices.map((packageprice, index) => {
                                    return <RoomPackagesPriceCard1 key={index} packageprice={packageprice} getAllPackagePrices={getAllPackagePrices} authRole={authRole} />
                                })
                            }

                        </tbody>

                    </Table>
                </div>

            </div>
            <hr />

            <SeasonPrice packageprices={packageprices} roomprices={roomprices}/>
            <hr />
            {/* 
            <div className='room-price-card' style={{ marginBottom: '50px' }}>

                <h6 className="title"> Rooms</h6>

                <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                    <Button size='sm' onClick={getAllRoomPrices} >Refresh<SpinnerSM state={refresh} /></Button>
                    <Table>
                        <thead>
                            <tr>
                                <th>code</th>
                                {
                                    packageprices.map((item, index) => {
                                        return <th key={index}>{item.title}</th>
                                    })
                                }
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                roomprices.map((item, index) => {
                                    return <RoomPriceCard authRole={authRole} key={index} roomprice={item} getAllRoomPrices={getAllRoomPrices} />
                                })
                            }
                        </tbody>

                    </Table>
                </div>

            </div> */}

            {/* <hr />
            <div className='room-price-card' style={{ marginBottom: '50px' }}>
                <h6 className="title"> Calendar links</h6>

                <CalendarLink authRole={authRole} />

            </div> */}

        </div>
    )
}
