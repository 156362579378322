
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Badge, Button, Card, Form, InputGroup, ListGroup, Modal, Table } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import SpinnerSM from '../../../components/Spinner/SpinnerSM';
import { userSelector } from '../../../store/reducers/user';
import AddSeasonPrice from './AddSeasonPrice';
import EditSeasonPrice from './EditSeasonPrice';
import target from '../../../db/target';
import dayjs from 'dayjs';
import ViewSeasonPrice from './ViewSeasonPrice';
import DeleteItem from '../../../components/DeleteItem/DeleteItem';

// const dataList = [
//     {
//         "title": "Deluxe Double Room With Rice Paddy view",
//         "start": "2024-05-13",
//         "end": "2024-05-14",
//         "prices": [
//             {
//                 "code": 303,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 26,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 38,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 38,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 68,
//                         "description": "Description"
//                     }
//                 ]
//             },
//             {
//                 "code": 302,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 26,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 41,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 30,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 60,
//                         "description": "Description"
//                     }
//                 ]
//             },
//             {
//                 "code": 203,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 16,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 31,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 38,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 68,
//                         "description": "Description"
//                     }
//                 ]
//             },
//             {
//                 "code": 202,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 26,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 31,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 39,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 69,
//                         "description": "Description"
//                     }
//                 ]
//             },
//             {
//                 "code": 304,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 26,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 31,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 38,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 48,
//                         "description": "Description"
//                     }
//                 ]
//             },
//             {
//                 "code": 101,
//                 "prices": [
//                     {
//                         "title": "Room Only",
//                         "price": 26,
//                         "description": "This only is room not included breakfast or dinner"
//                     },
//                     {
//                         "title": "Breakfast Included",
//                         "price": 31,
//                         "description": "you can get your breakfsat with us "
//                     },
//                     {
//                         "title": "Breakfast + Dinner Included",
//                         "price": 38,
//                         "description": "You can get your breakfast & dinner from us"
//                     },
//                     {
//                         "title": "Test Payment",
//                         "price": 68,
//                         "description": "Description"
//                     }
//                 ]
//             }
//         ]
//     }
// ]

const SeasonPriceCard = ({ data, getAllSeason }) => {

    const _default = data.title === 'default' ? true : false

    return <tr>
        <td>{data.title}</td>
        <td>{_default ? '-' : dayjs(data.start).format("YYYY-MM-DD")}</td>
        <td>{_default ? '-' : dayjs(data.end).format("YYYY-MM-DD")}</td>

        <div style={{ float: 'right', display: 'flex', gap: '20px' }}>
            <ViewSeasonPrice data={data} _default={_default} />
            <EditSeasonPrice getAllSeason={getAllSeason} data={data} _default={_default} />
            <DeleteItem code='DELETE' title={data.title} endpoint={`${target}api/season/`} params={{ _id: data._id }} refresh={getAllSeason} disabled={_default} />


        </div>
    </tr>

}


const SeasonPrice = ({ packageprices, roomprices }) => {


    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false
    const [refresh, setRefresh] = useState(false)
    const [seasonList, setSeasonList] = useState([])


    const getAllSeason = () => {
        setRefresh(true)
        axios.get(target + 'api/season/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            setSeasonList(res.data)
            setRefresh(false)
        })
            .catch(error => {
                console.log(error)
                setRefresh(false)
            })
    }



    useEffect(() => {
        getAllSeason()
    }, [])

    return (
        <div className='room-price-card' style={{ marginBottom: '50px' }}>

            <h6 className="title"> Season Packages</h6>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button size='sm' onClick={getAllSeason} >Refresh<SpinnerSM state={refresh} /></Button>
                    {authRole ? <AddSeasonPrice getAllSeason={getAllSeason} packageprices={packageprices} roomprices={roomprices} /> : ''}
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>title</th>
                            <th>start</th>
                            <th>end</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            seasonList.map((season, index) => {
                                return <SeasonPriceCard key={index} getAllSeason={getAllSeason} data={season} />
                            })
                        }
                    </tbody>

                </Table>
            </div>
        </div>
    )
}

export default SeasonPrice
