import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Table, Toast, ToastContainer } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import target from '../../db/target'
import { Backdrop, CircularProgress } from '@mui/material'
import { green } from '@mui/material/colors'





const MakePayment = () => {
    const user = useSelector(userSelector)
    const [btnState, setBtnState] = useState(true)

    const [refresh, setRefresh] = useState()

    const [openLoading, setOpenLoading] = useState(false);
    const [show, setShow] = useState(false)
    const [successShow, setSuccessShow] = useState(false)
    const [showHCO, setShowHCO] = useState(false)
    const [state, addState] = useState(false)
    const [showToast, setShowToast] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState({
        title: 'Form',
        message: 'All * details are required',
        state: 'warning',
    });

    const [paymentId, setPaymentId] = useState(0)
    const [sessionId, setSessionId] = useState()
    const [paymentData, setPaymentData] = useState({})


    const _price = useRef(0);
    const _title = useRef('');
    const _description = useRef('');


    const handleClose = () => {
        setBtnState(true)
        setShow(false)
    };
    const handleShow = () => {
        setSuccessShow(false)
        if (btnState) {
            setBtnState(false)
            setShow(true)
        } else {
            setBtnState(true)
            window.sessionClear();
            setShowHCO(false)
        }
    };

    const handlePayment = () => {

        // setBtnState(false)

        if (_price.current.value === '' || _title.current.value === '') {
            // alert('All details are required')
            setShowToastMsg({
                title: 'Form Warning',
                message: 'All * details are required',
                state: 'warning',
            })
            setShowToast(true)
        } else {

            setOpenLoading(true)

            setShowHCO(true)

            setBtnState(false)

            let _paymentData = {

                title: _title.current.value,
                price: parseInt(_price.current.value),
                description: _description.current.value,
                // successIndicator: 'success_indicator'
            }

            axios.get(target + 'api/payment/payment-id',
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                })
                .then(result => {
                    console.log(result);

                    const id = result.data.paymentId

                    setPaymentId(id)



                    const session = {
                        "interaction.merchant.name": _paymentData.title,
                        "order.id": id,
                        "order.amount": _paymentData.price,
                        "order.currency": "USD",
                        "order.description": `~Payment for Booking of HURF~\npayment-id: #${_paymentData.id}\ntitle: ${_paymentData.title}`,
                    }


                    axios.post(target + 'api/client/payment/session-id', session)
                        .then(result => {
                            console.log(result);


                            setSessionId(result.data['session.id'])

                            _paymentData = {
                                ..._paymentData,
                                id,
                                sessionId: result.data['session.id']
                            }
                            setPaymentData(_paymentData)


                            setTimeout(() => {

                                console.log(_paymentData);

                                window.config(result.data['session.id'])
                                window.run()
                                setOpenLoading(false)
                                setShowToastMsg({
                                    title: 'Information',
                                    message: 'Get Session id sucsesfully',
                                    state: 'info',
                                })
                                setShowToast(true)

                                setBtnState(false)
                            }, 500);

                        })
                        .catch(error => {
                            console.error(error);

                            setOpenLoading(false)
                            setShowToastMsg({
                                title: 'Erorr',
                                message: error.message,
                                state: 'danger',
                            })
                            setShowToast(true)
                            if (error.response.status === 403) {
                                window.location.reload()
                            }
                        })

                })
                .catch(error => {

                    setOpenLoading(false)
                    console.error(error);
                    setShowToastMsg({
                        title: 'Erorr',
                        message: error.message,
                        state: 'danger',
                    })
                    setShowToast(true)
                    if (error.response.status === 403) {
                        window.location.reload()
                    }
                })




            handleClose()
        }

    }



    function getAllPaidlist() {

        setRefresh(true)


    }

    useEffect(() => {
        getAllPaidlist()
    }, [])


    ///////////////////////////////////////------------------------------------------------------------ Payment Callback functions

    window.beginCallBack = function (data) {
        console.log(data);
        // setOpenLoading(true)
    }


    window.errorCallback = function (error) {
        console.error(error);
        setOpenLoading(false)
        setShowToastMsg({
            title: 'Erorr',
            message: error.message,
            state: 'danger',
        })
        setShowToast(true)
    }

    window.timeoutCallback = function () {
        console.error('Time Out');
        setShowToastMsg({
            title: 'Payment Erorr',
            message: 'Process is time out',
            state: 'danger',
        })
        setShowToast(true)
        setOpenLoading(false)
    }

    window.closedWindowCallBack = function () {
        console.log('Window Closed');
        setShowToastMsg({
            title: 'Payment Info',
            message: 'Process window is closed',
            state: 'info',
        })
        setShowToast(true)
        setOpenLoading(false)
    }

    ///////////////////////////////////////------------------------------------------------------------

    window.showComplete = function (results) {
        // data.setShowBack(false)
        storePayment(results.resultIndicator)
        console.log(results);


        console.log('payment is success');
        setShowToastMsg({
            title: 'Payment Success',
            message: 'payment was successfully completed',
            state: 'success',
        })
        setShowToast(true)
        setSuccessShow(true)

        setTimeout(() => {
            setSuccessShow(false)
        }, 10000);
    }

    function storePayment(success_indicator) {

        const _paymentData = {
            ...paymentData,
            successIndicator: success_indicator
        }

        axios.post(target + 'api/payment/create', _paymentData,
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            })
            .then(result => {
                console.log(result);

                setShowToastMsg({
                    title: 'Information',
                    message: 'payment was successfully completed and stored in db',
                    state: 'info',
                })
                setShowToast(true)
                setShowHCO(false)

            })
            .catch(error => {
                console.error(error);
                setShowToastMsg({
                    title: 'Erorr',
                    message: error.message,
                    state: 'danger',
                })
                setShowToast(true)
            })
    }

    return (
        <div className='room-price-card' style={{ marginBottom: '50px' }}>
            <h6 className="title" style={{backgroundColor: 'rgb(223, 226, 255)', padding: '10px 20px', borderRadius: '10px', fontWeight: '900'}}>Make a payment hear <Button size='sm' style={{ padding: '2px 5px', marginLeft: '30px' }} onClick={handleShow} >{btnState ? ' make' : 'cancel'}</Button> </h6>

            {
                showHCO ?
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                        <div style={{ textAlign: 'center' }}><h6>#{paymentId} : {paymentData.title}</h6><hr /></div>
                        <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid rgb(214, 214, 214)' }} id="hco-embedded"></div>
                    </div>
                    :
                    ""
            }

            {
                successShow ?
                    <div style={{ textAlign: 'center', border: '1px solid rgb(214, 214, 214)', margin: '30px 20px', padding: '20px 10px', borderRadius: '10px', backgroundColor: 'rgb(236, 255, 209)' }}>
                        <h4>Payment was Successfully Completed</h4>
                        <h3>Payment ID : #{paymentId}</h3>
                    </div>
                    : ""
            }


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Make a payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">Price ($) *</Form.Label>
                            <Form.Control ref={_price} size="sm" type="number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupCode">
                            <Form.Label column="sm">Title *</Form.Label>
                            <Form.Control ref={_title} size="sm" type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label column="sm">Description</Form.Label>
                            <Form.Control ref={_description} as="textarea" rows={3} size="sm" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handlePayment}> Pay  <SpinnerSM state={state} />  </Button>
                </Modal.Footer>
            </Modal>


            <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                    <Toast.Header >
                        <strong className="me-auto">{showToastMsg.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.message}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            // onClick={() => setOpenLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    )
}

export default MakePayment
