import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import axios from 'axios';
import { nanoid } from 'nanoid';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import target from '../../db/target';


export const EditBooking = ({ data, getAllBookings }) => {

    const { _id, id, status, cbook, info, price } = data
    const { name, email, phone } = info
    const {paid, total} = price
    
    // console.log(status);

    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)


    const [startDate, setStartDate] = useState(dayjs(data.in).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs(data.out).format("YYYY-MM-DD"));

    const [state, setState] = useState(status)

    const user = useSelector(userSelector)

    const _state = useRef()
    const _name = useRef()
    const _email = useRef()
    const _phone = useRef()
    const _paid = useRef()

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleEdit = () => {
        setEditState(true)


        const nights = new dayjs(endDate).diff(new dayjs(startDate), 'day')
        console.log(startDate, endDate, nights);

        const cbooks = data.room.map((item, index) => {
            return {
                id: data.id,
                code: item.code,
                uid: nanoid(32) + "@heavenuponricefields.com",
                start: new Date(startDate),
                end: new Date(endDate),
                title: _state.current.value,
                description: `CHECKIN: ${startDate}\nCHECKOUT: ${endDate}\nNIGHTS: ${nights}\nNAME: ${data.info.name}`,
            };
        });

        const _info = {
            name: _name.current.value,
            email: _email.current.value,
            phone: _phone.current.value
        }

        //   console.log(cbooks);

        axios.post(target + 'api/booking/update', {
            cbooks,
            bId: id,
            id: { _id },
            data: {
                status: _state.current.value
            },
            in: new Date(startDate),
            out: new Date(endDate),
            cbook,
            nights,
            info: _info,
            price : {
                total: total,
                paid:  _paid.current.value
            }
        },
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
            })
            .then(res => {
                // console.log(res)
                handleClose()
                // getAllStates()
                setEditState(false)
                getAllBookings()
            })
            .catch(err => {
                if (err.response.status === 403) {
                    window.location.reload()
                }
                // console.log(err.response.status);
                console.log(err)
                setEditState(false)
            })
        // setTimeout(() => {
        //     setEditState(false)
        // }, 1000);
    }

    useEffect(() => {
        setState(status)
    }, [])

    return (
        <div>

            <Button variant="primary" size='sm' onClick={handleShow}>edit</Button>

            <Modal show={show} onHide={handleClose} size='md'>

                <Modal.Header closeButton>
                    <Modal.Title>Edit the Booking Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">Status</Form.Label>
                        <Form.Select ref={_state} aria-label="Default select example" defaultValue={status} onChange={() => setState(_state.current.value)}>
                            <option value="Booked">Booked</option>
                            {/* <option value="Pending">Pending</option> */}
                            <option value="External">External</option>
                            <option value="Blocked">Blocked</option>
                            <option value="Canceled">Canceled</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupCode">
                        <Form.Label column="sm">Paid ($)</Form.Label>
                        <Form.Control ref={_paid} type='number' defaultValue={paid} placeholder='paid' />
                    </Form.Group>

                    <Card className='mb-3'>
                        <Card.Body>

                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextUsername">
                                <Form.Label column sm="3">Name</Form.Label>
                                <Col sm="9"><Form.Control ref={_name} type='text' defaultValue={name} placeholder='name' /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                <Form.Label column sm="3">Email</Form.Label>
                                <Col sm="9"><Form.Control ref={_email} type='email' defaultValue={email} placeholder='email' /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="" controlId="formPlaintextEmail">
                                <Form.Label column sm="3">Number</Form.Label>
                                <Col sm="9"><Form.Control ref={_phone} type='email' defaultValue={phone} placeholder='number' /></Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>


                    {
                        state === 'Canceled' ? '' :

                            <Card>
                                <Card.Body>
                                    <div className='edit-date-pickers'>
                                        <div>
                                            <p className='edit-date-pickers-title'>Start Date</p>
                                            <DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(dayjs(date).format("YYYY-MM-DD"))} />
                                        </div>
                                        <div>
                                            <p className='edit-date-pickers-title'>End Date</p>
                                            <DatePicker selected={new Date(endDate)} onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleEdit}> Save Changes <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
