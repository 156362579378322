import React, { useRef, useState } from 'react'
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';


const AddAccount = ({getAllAccounts}) => {

    const user = useSelector(userSelector)

    const _u = useRef()
    const _p = useRef()
    const _e = useRef()
    const _r = useRef()

    const [saveState, setSaveState] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handlerRole(e) {
        console.log(e.target.outerText);
        _r.current.value = e.target.outerText
    }

    function handleData() {
        setSaveState(true)

        // console.log(_u.current.value, _p.current.value, _e.current.value, _r.current.value);

        if (_e.current.value === '' || _p.current.value === '' || _u.current.value === '' || _r.current.value === '') {
            setSaveState(false)
            alert('Enter all data!')
        } if (_p.current.value.length < 8) {
            alert('The length of password must be greater than or equal to 8')
        } else {
            axios.post(target + "api/account/create", {
                username: _u.current.value,
                password: _p.current.value,
                email: _e.current.value,
                role: _r.current.value,
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                }

            )
                .then(res => {
                    if (res.data !== null) {
                        // console.log(res.data);
                        alert('add a new account')
                        getAllAccounts()
                    } else {
                        alert('Something gose worng, try again!')
                    }
                    setSaveState(false);
                    handleClose()
                })
                .catch(err => {
                    console.log(err);
                    setSaveState(false);
                    alert('Error with status code ' + err.request.status + ', ' + err.response.data)
                })
        }
    }


    return (
        <div >
            <Button onClick={handleShow} size='sm'>+ Add Account</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextUsername">
                            <Form.Label column sm="3">Username</Form.Label>
                            <Col sm="9"><Form.Control ref={_u} type='text' placeholder="username" /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="3">Email</Form.Label>
                            <Col sm="9"><Form.Control ref={_e} type='email' placeholder="email" /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="3"> Password </Form.Label>
                            <Col sm="9"> <Form.Control ref={_p} type="password" placeholder="password" /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextRole">
                            <InputGroup>
                                <Form.Label column sm="3" className='me-2'> Role </Form.Label>
                                <Form.Control ref={_r} readOnly sm="9" type="text" placeholder="role" />
                                <DropdownButton title='select' align="end" id='input-role'>
                                    <Dropdown.Item onClick={handlerRole}>admin</Dropdown.Item>
                                    <Dropdown.Item onClick={handlerRole}>view</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>  Close </Button>
                    <Button variant="primary" onClick={handleData}>  Save <SpinnerSM state={saveState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddAccount
