import { useEffect, useReducer, useRef } from 'react';
import './style.css'
import { useState } from 'react';
import axios from 'axios';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import ImageCard from '../../components/ImageCard/ImageCard';
import target from '../../db/target';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import OldPayment from './OldPayment';
import MakePayment from './MakePayment';








const Payment = () => {

    // const user = useSelector(userSelector)
    // const authRole = user.user.role === 'admin' ? true : false

    // const [show, setShow] = useState(false);
    // const [showToast, setShowToast] = useState(false);
    // const [showToastMsg, setShowToastMsg] = useState({
    //     title: 'Uploading Warning',
    //     message: 'You have not selected any images',
    //     state: 'success',
    // });



    // id: { type: Number, require: true, unique: true },
    // name: { type: String},
    // price: { type: Number, require: true },
    // created: { type: String, require: true },
    // description: { type: String },

    // function storePayment() {
    //     // axios.get(target + 'api/payment/payment-id',
    //     // {
    //     //     headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
    //     // })
    //     // .then(result => {
    //     //     console.log(result);


    //     // })
    //     // .catch(error => {
    //     //     console.error(error);
    //     //     console.log('Payment-ID: Error');
    //     // })

    //     const paymentData = {
    //         id: 100013,
    //         title: 'Arun',
    //         price: 36,
    //         description: 'Demo test For table',
    //         sessionId: 'sessionId',
    //         successIndicator: 'success_indicator'
    //     }

    //     axios.post(target + 'api/payment/create', paymentData,
    //         {
    //             headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
    //         })
    //         .then(result => {
    //             console.log(result);

    //         })
    //         .catch(error => {
    //             console.error(error);
    //             console.log('Loading ENd - error');
    //         })
    // }



    // function makePayment() {
    //     const session = {
    //         "interaction.merchant.name": 'Test Amal',
    //         "order.id": '1',
    //         "order.amount": 45,
    //         "order.currency": "USD",
    //         // "order.description": `~Payment for Booking of HURF~\nbooking-id: #${details.book.id}\nname: ${details.book.info.name}\nemail: ${details.book.info.email}\nnumber: ${details.book.info.phone}`,
    //         "order.description": `~Payment for Booking of HURF~`,
    //     }

    //     // console.log(session);
    //     console.log('Loading Start');

    //     axios.post(target + 'api/client/payment/session-id', session)
    //         .then(result => {
    //             console.log(result);

    //             // setPayState(false)

    //             // setSessionId(result.data['session.id'])

    //             setTimeout(() => {

    //                 window.config(result.data['session.id'])
    //                 window.run()
    //                 // setOpenLoading(false)
    //                 console.log('Loading ENd');

    //             }, 500);

    //         })
    //         .catch(error => {
    //             console.error(error);
    //             console.log('Loading ENd - error');
    //         })
    // }



    return (
        <div className='room-images'>
            <div className='add-title-box mb-4 d-flex align-items-center '>
                <h3 className="">Payment</h3>


                {/* <ToastContainer position='bottom-end' style={{ zIndex: 1150, position: 'fixed' }} className='m-4'>
                    <Toast onClose={() => setShowToast(false)} show={showToast} bg={showToastMsg.state} delay={3000} autohide>
                        <Toast.Header >
                            <strong className="me-auto">{showToastMsg.title}</strong>
                        </Toast.Header>
                        <Toast.Body>{showToastMsg.message}</Toast.Body>
                    </Toast>
                </ToastContainer> */}


            </div>

            <hr />

            <MakePayment/>

            <hr />

            <OldPayment />


            {/* <div className="offers-box">
                <Button onClick={storePayment}>storePayment</Button>

                <Button onClick={makePayment}>Pay</Button>
                <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid rgb(214, 214, 214)' }} id="hco-embedded"></div>


            </div> */}
        </div>
    )
}

export default Payment
