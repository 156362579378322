import { useEffect, useReducer, useState } from 'react';
import './style.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';

const FacilityItem = ({ item, index, dispatch }) => {

    const { title, iconUrl, state } = item

    const [_state, setState] = useState(state)

    return <ListGroup.Item>
        <Form.Check
            type='checkbox'
            id={`default-checkbox-${index}`}
            label={title}
            checked={_state}
            onChange={() => {
                setState(!_state)
                dispatch({
                    type: 'change',
                    state: !_state,
                    title: title
                })
            }}
        />
    </ListGroup.Item>
}




const FacilitySelector = ({ dispatch, facilities, edit = 'edit' }) => {

    const user = useSelector(userSelector)




    const reduser = (state, action) => {

        if (action.type === 'change') {
            for (let index = 0; index < state.length; index++) {
                if (state[index].title == action.title) {
                    state[index].state = action.state
                }
            }
        } else if (action.type === 'load') {

            getFacilitis();

        } else if (action.type === 'set') {
            return action.data
        }

        return state
    }




    // console.log(facilities);
    const [show, setShow] = useState(false);

    const [facilitisCount, facilityDispatch] = useReducer(reduser, [])

    function getFacilitis() {

        axios.get(target+'api/facility/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                console.log(res);
                facilityDispatch({
                    type: 'set',
                    data: res.data
                })
            })
            .catch(err => {
                console.log(err.message)
            })


    }

    const handleItems = () => {
        handleClose()
        dispatch({
            type: 'change',
            facilities: facilitisCount
        })
    }

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)

        if (facilities.length <= 0 && edit !== 'edit') {
            facilityDispatch({
                type: 'load'
            })
        }

        // if(edit ==='edit'){
        //     facilityDispatch({
        //         type: 'load'
        //     })
        // }


    };


    useEffect(() => {
        if (edit === 'edit') {
            facilityDispatch({
                type: 'load'
            })
        }
    }, [])


    return (
        <div className='mt-1' style={{ textAlign: 'right' }}>
            <Button variant="outline-primary" className="me-2 mb-2" onClick={handleShow} size='sm'>Select Facilitis</Button>

            <Modal show={show} onHide={handleClose} size='sm'>
                <Modal.Header closeButton>
                    <Modal.Title>Select Facilitis</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {facilitisCount.map((item, index) => {
                            return <FacilityItem key={index} item={item} index={index} dispatch={facilityDispatch} />
                        })}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleItems}>  Save Changes </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default FacilitySelector