import dayjs from 'dayjs';
import React, { useRef, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'

import DatePicker from "react-datepicker";


const CalenderControler = ({setAsMonth, setAsCustom, refresh}) => {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new dayjs())

    // console.log(selectedMonth);

    const [dateMode, setDateMode] = useState(false)

    const _s = useRef()


    const showHandler = () => {

        const x = dayjs(startDate)
        const y = dayjs(endDate)

        // console.log();

        if(y.diff(x, 'day') > 99){
            alert('Select less that 100 days for period')
        } else{
            dateMode ? setAsCustom(new dayjs(startDate).format("YYYY-MM-DD"), new dayjs(endDate).format("YYYY-MM-DD")) : setAsMonth(dayjs(selectedMonth.format('YYYY-MM-DD')))
        }

        // console.log(startDate, endDate);
    //    refresh(startDate, endDate)
    }


    const pariodSelector = (e) => {
        // console.log(_s.current.value);
        setDateMode(_s.current.value !== 'Month')
    }


    return (
        <Card style={{ marginBottom: '20px', padding: '10px', }}>
            <div className='calender-c-box'>

                <label>Period: </label>

                <Form.Select ref={_s} onChange={pariodSelector} style={{ width: '120px' }} >
                    <option style={{ width: '120px' }} value="Month">Month</option>
                    <option style={{ width: '120px' }} value="Custom">Custom</option>
                </Form.Select>


                {
                    dateMode
                        ?
                        <div className="calender-custom">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText='from' />
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} placeholderText='until' />
                        </div>
                        :
                        <div className="calender-custom">
                            <Button size='sm' onClick={() => {setSelectedMonth(selectedMonth.subtract(1, 'month'))}}> {"<"} </Button>
                            <label style={{width: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>{selectedMonth.format('MMMM')} <span style={{fontSize: '12px'}}>{selectedMonth.year()}</span></label>
                            <Button size='sm' onClick={() => {setSelectedMonth(selectedMonth.add(1, 'month'))}}> {">"} </Button>
                        </div>
                }


                <Button size='sm' style={{ marginLeft: '20px' }} onClick={showHandler}>Show</Button>

            </div>

        </Card>
    )
}

export default CalenderControler
