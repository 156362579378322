import { createSlice } from "@reduxjs/toolkit"

const initialState = null

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logIn: (state, action) => {
            
            return action.payload;
        },
        logOut: (state, action) => {
            return null
        }
    }
})

export const userSelector = (store) => store.user
export const {logIn, logOut} = userSlice.actions
export default userSlice.reducer