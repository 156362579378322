import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import SpinnerSM from '../Spinner/SpinnerSM';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';


const DeleteItem = ({ title = 'Room', code = '000', params, endpoint, refresh, disabled = false }) => {

    const user = useSelector(userSelector)

    const [errorMsgState, setErrorMsgState] = useState(false)

    const [deleteState, setDeleteState] = useState(false)
    const deleteComform = useRef()
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dataHandler = () => {
        if (deleteComform.current.value !== code) {
            setErrorMsgState(true)
            setTimeout(() => setErrorMsgState(false), 3000);

        } else {
            setErrorMsgState(false)

            console.log('DELETED:');

            setDeleteState(true)

            axios.delete(endpoint, {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                params
            })
                .then(res => {
                    if (res.status === 202) {
                        console.log('DELETE-OK: ', res);
                        // dispatch({ type: 'deleted', refresh: true })
                    }
                    setDeleteState(false)
                    setShow(false)
                    refresh()
                })
                .catch(error => {
                    console.error('DELETE-ERROR: ', error)
                    setDeleteState(false)
                    // dispatch({
                    //     type: 'error', data: {
                    //         title: 'Deleting Warning',
                    //         message: error.message,
                    //         state: 'danger',
                    //     }
                    // })
                })
        }
    }

    return (
        <div>
            <Button variant="danger" disabled={disabled} size='sm' onClick={handleShow}>delete</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="exampleFormControlInput1" className="form-label delete-text ">write "<span>{code}</span>" to delete</label>
                    <input ref={deleteComform} type="test" className="form-control" id="exampleFormControlInput1" />
                    {errorMsgState ? <h6 style={{ color: 'red', fontSize: '11px', paddingLeft: '2px', paddingTop: '2px' }}>Enter the word correct</h6> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="danger" onClick={dataHandler}> Delete <SpinnerSM state={deleteState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteItem
