
import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM'
import RoomStateEdit from './RoomStateEdit'
import RoomStateAdd from './RoomStateAdd'
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import axios from 'axios'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import target from '../../db/target'



const RoomStateCard = ({ code, data, getAllStates }) => {

    const user = useSelector(userSelector)

    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    const { _id, title, id } = data

    return <tr>
        <th>#{data.id}</th>
        <th>{data.title}</th>
        <th>{new dayjs(data.start).format('YYYY-MM-DD')}</th>
        <th>{new dayjs(data.end).format('YYYY-MM-DD')}</th>
        {authRole ? <th style={{ display: 'flex', gap: '10px', justifyContent: 'end' }} >
            <RoomStateEdit code={code} getAllStates={getAllStates} data={data} />
            <DeleteItem code='DELETE' title={'#' + id} endpoint={`${target}api/cbook/`} params={{ _id }} refresh={getAllStates} />
        </th> : ''}
    </tr>
}


const RoomStateBox = ({ code, refresh, setRefresh }) => {

    const [roomStates, setRoomStates] = useState([])
    const [show, setShow] = useState(false)

    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false


    function getAllStates() {
        setRefresh(!refresh)

        axios.get(target + 'api/cbook/status', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
            params: { code }
        })
            .then(res => {
                setRoomStates(res.data)
                // console.log(res.data);
            })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        getAllStates()
    }, [])


    return <div style={{ backgroundColor: 'white', borderRadius: '10px', margin: '20px 0', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
        {/* <Button size='sm' style={{ maxWidth: '100px' }} onClick={getAllRoomPrices} >Refresh<SpinnerSM state={refresh} /></Button>
         */}


        <div style={{ backgroundColor: 'rgb(241, 241, 241)', padding: '5px', borderRadius: '5px', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
            <Typography variant='h5'>{code} Room</Typography>
            <Button size='sm' onClick={() => setShow(!show)}>show</Button>
            {authRole ? <RoomStateAdd code={code} getAllStates={getAllStates} /> : ''}
        </div>
        {
            show ?
                <Table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>state</th>
                            <th>start</th>
                            <th>end</th>
                            {authRole ? <th></th> : ''}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            roomStates.map((data, index) => {
                                return <RoomStateCard key={index} data={data} code={code} getAllStates={getAllStates} />
                            })
                        }



                        {/* <tr >
                    <th>#3456</th>
                    <th>Blocked</th>
                    <th>H</th>
                    <th>H</th>
                    <th style={{ display: 'flex', gap: '10px', justifyContent: 'end' }} >
                        <RoomStateEdit code={code} />
                        <DeleteItem />

                    </th>
                </tr> */}
                        {
                            // curls.map((curl, index) => {
                            //     return <tr key={index}>
                            //         <th style={{ fontWeight: '400' }}>{curl.code}</th>
                            //         <th style={{ fontWeight: '400' }}>{curl.import ? curl.import : '-'}</th>
                            //         <th style={{ fontWeight: '400' }}>{curl.export ? curl.export : '-'}</th>
                            //         <th style={{ fontWeight: '400' }}>
                            //             {
                            //                 authRole ? <EditCalenderLink user={user} curl={curl} getAllUrls={getAllUrls} /> : ''
                            //             }
                            //         </th>
                            //     </tr>
                            // })
                        }
                    </tbody>
                </Table>
                :
                ''
        }
    </div >
}



const RoomState = ({refresh, setRefresh}) => {



    const user = useSelector(userSelector)

    const [rooms, setRooms] = useState([])


    function getAllRoomPrices() {


        axios.get(target + 'api/cbook/rooms', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        }).then(res => {
            // console.log(res.data);
            setRooms(res.data)
        })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getAllRoomPrices()
    }, [])

    useEffect(() => {
        getAllRoomPrices()
    }, [refresh])


    return (
        <div>

            {/* <RoomStateBox /> */}
            {
                rooms.map((code, index) => {
                    return <RoomStateBox key={index} code={code} refresh={refresh} setRefresh={setRefresh}/>
                })
            }
        </div>
    )
}

export default RoomState
