import React, { useRef, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import axios from 'axios';
import target from '../../db/target';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';

const DiscountAndTaxAdd = ({ getAllDiscouts }) => {

    const user = useSelector(userSelector)

    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)


    const [startDate, setStartDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

    const _title = useRef()
    const _method = useRef()
    const _operator = useRef()
    const _limit = useRef()
    const _amount = useRef()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleAdd() {

        const discountTaxData = {
            title: _title.current.value,
            method: _method.current.value,
            operator: _operator.current.value,
            limit: _limit.current.value,
            amount: _amount.current.value,
            start: startDate,
            end: endDate
        }

        console.log(discountTaxData);

        setEditState(true)

        if (discountTaxData.title === '' || discountTaxData.method === '' || discountTaxData.operator === '' || discountTaxData.limit === '' || discountTaxData.amount === '') {
            alert('All details are required')
        } else {

            console.log(discountTaxData);

            axios.post(target + 'api/discount-tax/create', discountTaxData,
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` },
                })
                .then(res => {
                    console.log(res)
                    setEditState(false)
                    handleClose()
                    getAllDiscouts()
                })
                .catch(err => {
                    console.log(err.message)
                    setEditState(false)
                    // alert(err.message)
                })
        }

    }

    return (
        <div>
            <Button size='sm' onClick={handleShow}>add</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Discount & Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Title</Form.Label>
                        <Form.Control ref={_title} size="sm" type="test" />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Method</Form.Label>
                        <Form.Select ref={_method} aria-label="Default select example">
                            <option value="presentage">Percentage </option>
                            <option value="value">Value</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Operator</Form.Label>
                        <Form.Select ref={_operator} aria-label="Default select example">
                            <option value="+">Add (+) </option>
                            <option value="-">Sub (-)</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Limit</Form.Label>
                        <Form.Control ref={_limit} size="sm" type="number" />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Amount</Form.Label>
                        <Form.Control ref={_amount} size="sm" type="number" />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Date Range</Form.Label>
                        <Card>
                            <Card.Body>
                                <div className='edit-date-pickers'>
                                    <div>
                                        <p className='edit-date-pickers-title'>Start Date</p>
                                        <DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                    <div>
                                        <p className='edit-date-pickers-title'>End Date</p>
                                        <DatePicker selected={new Date(endDate)} onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleAdd} > Add <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DiscountAndTaxAdd
