import axios from "axios";
import "./style.css";
import SpinnerSM from "../../components/Spinner/SpinnerSM";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../store/reducers/user";
import target from "../../db/target";

const Login = () => {
  // store
  const dispatch = useDispatch();

  // console.log(target);

  // console.log('USER: ',user);

  const [state, setState] = useState(false);
  const navigate = useNavigate();


  const [allInputs, setAllInputs] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState(
    "enter the correct username and password"
  );
  const [usernameInput, setUsernameInput] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);

  const inputchangehandeler = (e) => {
    setAllInputs(false);
    setUsernameInput(false);
    setPasswordInput(false);
  };

  const login = (e) => {
    e.preventDefault();

    const username = e.target.form[0].value;
    const password = e.target.form[1].value;

    if (username === "") {
      setUsernameInput(true);
    }

    if (password === "") {
      setPasswordInput(true);
    }

    if (username && password) {
      requestToLogin(e);
      setState(true);
    }
  };

  function requestToLogin(e) {

    const url = target + "api/user/login"

    // console.log(url);

    axios
      .post(url, {
        username: e.target.form[0].value,
        password: e.target.form[1].value,
      })
      .then((res) => {
        // console.log(res.status)
        // console.log(res.data)

        if (res.data !== null) {
          dispatch(logIn(res.data));

          navigate("/dashboard", { replace: true, state: res.data });
        } else {
          setInvalidMsg("Somethig gose worng!");
          setAllInputs(true);
          setState(false);
        }

        setState(false);
      })
      .catch((err) => {
        console.log("Error", err);
        // console.log(
        //   err.response.data,
        //   err.response.status,
        //   err.response.statusText,
        //   err.message
        // );

        setInvalidMsg(err.message);
        setAllInputs(true);
        setState(false);
      });
  }

  return (
    <div className="login">
      {/* <h4 className='title-login'>Heaven Upon Rice Fields</h4> */}
      <form className="login-form ">
        <div className="login-title-box">
          <h4 className="mb-4">Dashboard Login (HURF)</h4>
        </div>
        <h6 className={`invalid-data-all ${allInputs ? "invalid" : ""}`}>
          {invalidMsg}
        </h6>
        <div className="mb-3 ">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control input-box"
            id="username"
            name="username"
            required
            onChange={inputchangehandeler}
          />
          <div
            className={`invalid-data-username ${usernameInput ? "invalid" : ""
              }`}
          >
            Please enter the username.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control input-box"
            aria-labelledby="passwordHelpBlock"
            name="password"
            required
            onChange={inputchangehandeler}
          />
          <div
            className={`invalid-data-password ${passwordInput ? "invalid" : ""
              }`}
          >
            Please enter the password.
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary submit_btn-login "
          onClick={login}
        >
          Login <SpinnerSM state={state} />
        </button>
      </form>
    </div>
  );
};

export default Login;
