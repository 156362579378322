import React, { useRef, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import axios from 'axios';
import target from '../../db/target';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';

const DiscountAndTaxEdit = ({ data, getAllDiscouts }) => {


    const user = useSelector(userSelector)

    const [show, setShow] = useState(false);
    const [editState, setEditState] = useState(false)

    
    const [startDate, setStartDate] = useState(dayjs(data.start).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs(data.end).format("YYYY-MM-DD"));

    const _title = useRef()
    const _method = useRef()
    const _operator = useRef()
    const _limit = useRef()
    const _amount = useRef()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleEdit() {


        const enteredValues = {
            title: _title.current.value,
            method: _method.current.value,
            operator: _operator.current.value,
            limit: _limit.current.value,
            amount: _amount.current.value,
            start: startDate,
            end: endDate
        }

        // console.log(enteredValues);

        setEditState(true)

        // console.log(price, parseInt(_price.current.value));

        if (enteredValues.title === '' || enteredValues.method=== '' || enteredValues.operator === '' || enteredValues.limit === '' || enteredValues.amount === '') {
            alert('All details are required')
            setEditState(false)

        }
        //  else if (parseInt(enteredValues.limit) === data.limit && parseInt(enteredValues.amount) === data.amount && enteredValues.title === data.title && enteredValues.method === data.method && enteredValues.operator === data.operator) {
        //     console.log('No update');
        //     alert('No update')
        //     setEditState(false)
        // } 
        else {
            axios.post(target + 'api/discount-tax/update', {
                id: data._id ,
                discounttax: enteredValues
            },
                {
                    headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
                })
                .then(res => {
                    // console.log(res)
                    handleClose()
                    getAllDiscouts()
                    setEditState(false)
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        window.location.reload()
                    }
                    console.log(err)
                    alert(err.message)
                    setEditState(false)
                })
        }


    }

    return (
        <div >
            <Button size='sm' onClick={handleShow}>Edit</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit a Discount & Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Title</Form.Label>
                        <Form.Control ref={_title} size="sm" type="test" defaultValue={data.title} />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Method</Form.Label>
                        <Form.Select ref={_method} aria-label="Default select example" defaultValue={data.method}>
                            <option value="presentage">Percentage </option>
                            <option value="value">Value</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Operator</Form.Label>
                        <Form.Select ref={_operator} aria-label="Default select example" defaultValue={data.operator}>
                            <option value="+">Add (+) </option>
                            <option value="-">Sub (-)</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Limit</Form.Label>
                        <Form.Control ref={_limit} size="sm" type="number" defaultValue={data.limit} />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Amount</Form.Label>
                        <Form.Control ref={_amount} size="sm" type="number" defaultValue={data.amount} />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formGroupCode">
                        <Form.Label column="sm">Date Range</Form.Label>
                        <Card>
                            <Card.Body>
                                <div className='edit-date-pickers'>
                                    <div>
                                        <p className='edit-date-pickers-title'>Start Date</p>
                                        <DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                    <div>
                                        <p className='edit-date-pickers-title'>End Date</p>
                                        <DatePicker selected={new Date(endDate)} onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={handleEdit} > Edit <SpinnerSM state={editState} /> </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DiscountAndTaxEdit
