import './style.css'
import SpinnerMS from '../../components/Spinner/SpinnerSM'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/reducers/user'
import { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import { EditBooking } from './EditBooking'

import target from '../../db/target';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap'


const initBookings = []

const BookingTableRow = ({ bookingRow, getAllBookings, authRole, countHendaler }) => {
    const { created, id, info, _id, nights, price, room, status } = bookingRow
    const newbookValue = bookingRow?.newbook !== undefined ? bookingRow.newbook : false
console.log(bookingRow);
    const user = useSelector(userSelector)

    function showHeandler(params) {

        axios.post(target + 'api/booking/update-show', {
            _id,
            newbook: params.target.checked
        },
            {
                headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
            })
            .then(res => {
                countHendaler()
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    useEffect(() => {
        countHendaler()
    }, [])

    return <tr>
        <th scope="row" >
            <Form>
                <Form.Check onChange={showHeandler} defaultChecked={newbookValue} />
            </Form>
        </th>
        <th scope="row" >#{id}</th>
        <td>
            {
                room.map((item, index) => {
                    return <p key={index} className='mb-0'>{item.code}</p>
                })
            }
        </td>
        <td>{status}</td>
        <td ><p className='mb-0'>{new dayjs(bookingRow.in).format('YYYY-MM-DD')} - {new dayjs(bookingRow.out).format('YYYY-MM-DD')} <span className='' style={{ fontSize: '10px' }}></span></p><p className='mb-0 fst-italic ' style={{ fontSize: '12px' }}>{nights} nights</p></td>
        <td>
            {
                room.map((item, index) => {
                    return <p key={index} className='mb-0'>A: {item.adults} {item.children === 0 ? '' : `C: ${item.children}`}</p>
                })
            }
        </td>
        <td style={{ minWidth: '60px' }} >
            {
                room.map((item, index) => {
                    return <p key={index} className='mb-0'>{item.package}</p>
                })
            }
        </td>
        <td ><p className='mb-0'>{info.name}</p><p className='mb-0'>{info.email}</p><p className='mb-0'>{info.phone}</p>{ info?.country ? <p className='mb-0'>{info.country}</p> : ''}</td>
        <td style={{ minWidth: '60px' }} ><p className='mb-0'>Total: {price.total}</p> <p>Paid: {price.paid}</p></td>
        <td>{new dayjs(created).format('YYYY-MM-DD')}</td>
        {authRole ? <td><EditBooking data={bookingRow} getAllBookings={getAllBookings} /> </td> : ''}

    </tr>
}

const BookingTable = () => {

    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    const [bookings, setBookings] = useState(initBookings)
    const [bookingsShow, setBookingsShow] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [showValue, setShowValue] = useState(10)
    const [showAll, setShowAll] = useState(false)
    const [newCount, setNewCount] = useState(0)

    const _nobookings = useRef()

    useEffect(() => {
        setTimeout(() => {
            getAllBookings()
        }, 1000);

    }, [])


    async function getAllBookings() {

        setRefresh(true)

        axios.get(target + 'api/booking/all', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                const data = res.data.sort(function (a, b) { return b.id - a.id });
                console.log(data);
                setBookings(data)
                setBookingsShow(data)
                showBooks(10, false, data)
                setShowValue(10)
                setRefresh(false)
            })
            .catch(error => {
                console.log(error)
                setRefresh(false)
            })
    }

    function showChange(params) {
        setShowAll(params.target.checked)
        showBooks(showValue, params.target.checked, [])
    }

    function showOnly(params) {
        var count = Number(params.target.value)
        count = count > 0 ? count : 0
        setShowValue(count)
        showBooks(count, showAll, [])
    }

    function showBooks(value, state, data) {
        var temp = []
        if (data.length > 0) temp = data
        else temp = bookings
        state ? setBookingsShow(bookings) : setBookingsShow(temp.slice(0, value))
    }

    function countHendaler() {
        axios.get(target + 'api/booking/count', {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                // console.log(res);
                setNewCount(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className='_booking-table-box '>

            <div className='_booking-table-box-bar '>

                <button type="button" className="btn btn-primary btn-sm" onClick={getAllBookings} >Refresh<SpinnerMS state={refresh} /> </button>
                <h6 className={`mt-1 mb-0 invalid-data-all ${true ? 'invalid' : ''}`}>OK</h6>

                <Form className='_booking-table-box-bar-show'>
                    {showAll ? "" : <Col style={{ width: '80px', fontSize: '12px' }}><Form.Control ref={_nobookings} type='number' value={showValue} onChange={showOnly} /></Col>}

                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Show all"
                        onChange={showChange}
                        style={{ margin: '0' }}
                    />

                </Form>
            </div>

            <table className="_booking-table table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col"><Badge bg='secondary' onClick={countHendaler}>{newCount}</Badge></th>
                        <th scope="col">ID</th>
                        <th scope="col">Code</th>
                        <th scope="col">Status</th>
                        <th scope="col">Check-in/out</th>
                        <th scope="col">Guests</th>
                        <th scope="col">Package</th>
                        <th scope="col" >Customer Info</th>
                        <th scope="col">Price</th>
                        {/* <th scope="col">Accommodation</th> */}
                        <th scope="col">Date</th>
                        {authRole ? <th scope='col'></th> : ''}
                    </tr>
                </thead>
                <tbody>
                    {bookingsShow.map((bookingRow, index) => {
                        return <BookingTableRow key={index} authRole={authRole} bookingRow={bookingRow} getAllBookings={getAllBookings} countHendaler={countHendaler} />
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default BookingTable
