
import React, { createRef, useRef, useState } from 'react'
import { Button, Card, Form, Modal, Table, Toast, ToastContainer } from 'react-bootstrap';
import DatePicker from "react-datepicker";


const ViewSeasonPrice = ({ data, _default }) => {

    const dataObj = data

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)


    return <div>
        <Button variant="primary" size='sm' onClick={handleShow}>view</Button>
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>View the package</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                        <Form.Label column="sm">Title *</Form.Label>
                        <Form.Control size="sm" type="text" defaultValue={dataObj.title} readOnly />
                    </Form.Group>

                    {
                        _default ? '' : <Card>
                            <Card.Body>
                                <div className='edit-date-pickers'>
                                    <div>
                                        <p className='edit-date-pickers-title'>Start Date</p>
                                        <DatePicker selected={new Date(dataObj.start)} readOnly />
                                    </div>
                                    <div>
                                        <p className='edit-date-pickers-title'>End Date</p>
                                        <DatePicker selected={new Date(dataObj.end)} readOnly />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    }
                    <Card style={{ marginTop: '20px' }}>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr style={{ fontSize: '12px' }}>
                                        <th>code</th>
                                        {
                                            dataObj.prices[0].prices.map((packageprice, index) => {
                                                return <th key={index}>{packageprice.title}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        dataObj.prices.map((item, index) => {
                                            return <tr key={index} style={{ fontSize: '14px' }} >
                                                <td >{item.code}</td>
                                                {
                                                    item.prices.map((packageItem, index) => {
                                                        return <td key={index}>
                                                            <Form.Group className="" controlId="formGroupTitle" style={{ width: '70px' }}>
                                                                <Form.Control size="sm" type="number" defaultValue={packageItem.price} readOnly />
                                                            </Form.Group>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default ViewSeasonPrice
