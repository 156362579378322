import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import target from '../../db/target';
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import dayjs from 'dayjs';

const TodayTableCard = ({ data, getAllActivity }) => {


    // const user = useSelector(userSelector)
    // const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    return <tr>
        <th style={{ fontWeight: '400' }}>#{data.id}</th>
        <th style={{ fontWeight: '400' }}>{
            data.room.map((item, index) => {
                return <p key={index} className='mb-0'>{item.code}</p>
            })
        }</th>
        <th style={{ fontWeight: '400' }}>{data.status}</th>
        <th style={{ fontWeight: '400' }}>{data.price.paid === 0 ? 'Not paid' : 'Paid'}</th>

    </tr>
}

const TodayTable = ({ title }) => {


    const _endpoint = title === 'Arrival' ? 'arrival' : 'departure';

    const [refresh, setRefresh] = useState(false)

    const [activity, setActivity] = useState([])

    const user = useSelector(userSelector)
    const authRole = user !== null ? (user.user.role === 'admin' ? true : false) : false

    function getAllActivity() {

        setRefresh(true)

        const data = {
            date: new dayjs().format("YYYY-MM-DD")
            // date: '2024-03-02'
        }


        axios.post(target + 'api/booking/'+_endpoint, data, {
            headers: { 'authorization': `bearer ${user ? user.accessToken : ''}` }
        })
            .then(res => {
                console.log(res);
                // setNewCount(res.data)
                setActivity(res.data)
                
                setRefresh(false)
            })
            .catch(error => {
                console.log(error)
                alert(error.message)
                setRefresh(false)
            })

    }

    useEffect(() => {
        getAllActivity()
    }, [])

    return (
        <div className='room-price-card' style={{ marginBottom: '20px' }}>
            <h6 className="title"> {title}</h6>

            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', border: '1px solid rgb(224, 224, 224)', fontSize: '14px' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Button size='sm' onClick={getAllActivity} >Refresh<SpinnerSM state={refresh} /></Button>
                    {/* {authRole ? <DiscountAndTaxAdd getAllDiscouts={getAllDiscouts} /> : ''} */}
                   {activity.length <= 0 ?  <h6 style={{marginBottom: '0', marginLeft: '20px'}}> - No {_endpoint} today</h6>  : ''}              
                </div>

                {
                   activity.length > 0 ?
                    <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>code</th>
                            <th>status</th>
                            <th>price</th>

                        </tr>
                    </thead>
                    <tbody>

                        {
                            activity.map((data, index) => {
                                return <TodayTableCard data={data} key={index} getAllActivity={getAllActivity} />
                            })
                        }

                    </tbody>
                </Table>
                : ''

                }
            </div>
        </div>
    )

}


const TodayActivity = () => {


    return (
        <div>
            <TodayTable title="Arrival" />
            <TodayTable title="Departure" />
            <br />
        </div>
    )
}

export default TodayActivity
