
import React, { useEffect, useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SpinnerSM from '../Spinner/SpinnerSM'
import { useDispatch, useSelector } from 'react-redux'
import { logOut, userSelector } from '../../store/reducers/user'
import target from '../../db/target'
import logo from './hurf-logo.jpg';

const avatarViewUrl = "https://cdn.icon-icons.com/icons2/1095/PNG/512/1485476008-artboard-1_78539.png"
const avatarUrl = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Circle-icons-tools.svg/1200px-Circle-icons-tools.svg.png"
const avatarImageUrl = "https://www.freeiconspng.com/uploads/multimedia-photo-icon-31.png"

export const SideBar = () => {

    const user = useSelector(userSelector)
    const dispatch = useDispatch()
    // console.log('USER-DATA: ', user);


    const [logoutState, setLogoutState] = useState(false)
    const [logoutErrorMsg, setLogoutErrorMsg] = useState('')

    const [page, setPage] = useState('dashboard')
    const navigate = useNavigate()

    const pageNavigate = (page) => {
        setPage(`${page}`)
        navigate(`${page === 'home' ? '/' : page}`, { replace: true })
    }

    useEffect(() => {
        if (user === null) {
            navigate('/', { replace: true })
            console.log('Navigated to login page!');
        }
    }, [])


    async function logoutHandler() {
        setLogoutErrorMsg('')
        setTimeout(() => {
            setLogoutErrorMsg('')
        }, 5000);

        setLogoutState(true)
        axios.delete(target + 'api/user/logout', {
            data: { refreshToken: user.refreshToken }
        })
            .then(res => {
                setLogoutState(false)
                if (res.status === 202) {
                    dispatch(logOut())
                    navigate('/', { replace: true })
                }

            })
            .catch(error => {
                setLogoutState(false)
                console.error('LOGOUT-ERROR: ', error)
                setLogoutErrorMsg(error.message)
            })
    }


    return (
        <div className='sidebar'>

            <div className="profile">
                {/* <img width={70} src="https://adviceco.com.au/wp-content/uploads/sites/683/2019/10/profile-icon-male-user-person-avatar-symbol-vector-20910833.png" alt="profile icon" /> */}
                <img width={70} src={logo} style={{borderRadius:"50%"}} alt="profile icon" />
                <h6 style={{ fontSize: '10px', marginBottom: '0', marginTop: '5px' }}>{user === null ? '' : user.user.role}</h6>
                <h5 style={{ borderBottom: '1px solid red', paddingBottom: '5px' }}>{user === null ? '' : user.user.username}</h5>
            </div>

            <div className="btn-group-vertical w-100" role="group" aria-label="Vertical button group">
                <h6 className='sidebar-menu-title ms-4 mt-4 fw-normal'>Genaral</h6>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'dashboard' ? 'active' : ''}`} onClick={() => pageNavigate('dashboard')}><img src={avatarViewUrl} width={25} alt="avatarUrl" className='me-3' />Dashboard</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'calendar' ? 'active' : ''}`} onClick={() => pageNavigate('calendar')}><img src={avatarViewUrl} width={25} alt="avatarUrl" className='me-3' />Calendar</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'offers' ? 'active' : ''}`} onClick={() => pageNavigate('offers')}><img src={avatarViewUrl} width={25} alt="avatarUrl" className='me-3' />Offers</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'payment' ? 'active' : ''}`} onClick={() => pageNavigate('payment')}><img src={avatarViewUrl} width={25} alt="avatarUrl" className='me-3' />Payment</button>

                <h6 className='sidebar-menu-title ms-4 mt-4 fw-normal'>Accommodation </h6>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'price' ? 'active' : ''}`} onClick={() => pageNavigate('price')}><img src={avatarUrl} width={25} alt="avatarUrl" className='me-3' />Price</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'rooms' ? 'active' : ''}`} onClick={() => pageNavigate('rooms')}><img src={avatarUrl} width={25} alt="avatarUrl" className='me-3' />Rooms</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'facilities' ? 'active' : ''}`} onClick={() => pageNavigate('facilities')}> <img src={avatarUrl} width={25} alt="avatarUrl" className='me-3' />Facilities</button>

                <h6 className='sidebar-menu-title ms-4 mt-4 fw-normal'>Storage </h6>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'gallery' ? 'active' : ''}`} onClick={() => pageNavigate('gallery')}><img src={avatarImageUrl} width={25} alt="avatarUrl" className='me-3' />Gallery</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'roomimages' ? 'active' : ''}`} onClick={() => pageNavigate('roomimages')}><img src={avatarImageUrl} width={25} alt="avatarUrl" className='me-3' />Images</button>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'facilityicons' ? 'active' : ''}`} onClick={() => pageNavigate('facilityicons')}><img src={avatarImageUrl} width={25} alt="avatarUrl" className='me-3' />Icons</button>

                <h6 className='sidebar-menu-title ms-4 mt-4 fw-normal'>Settings </h6>
                <button type="button" className={`_btn btn fs-5 btn-outline-primary ${page === 'accounts' ? 'active' : ''}`} onClick={() => pageNavigate('accounts')}><img src={avatarImageUrl} width={25} alt="avatarUrl" className='me-3' />Accounts</button>
            </div>

            <hr />
            <div className='pt-2 p-4 ' style={{ display: 'flex', justifyContent: 'center' }}>
                <button type="button" className="btn btn-danger btn-sm" onClick={logoutHandler}>Log out <SpinnerSM state={logoutState} /> </button>
                <h6 className={`mt-1 mb-0 invalid-data-all ${logoutErrorMsg ? 'invalid' : ''}`}>{logoutErrorMsg}</h6>
            </div>
        </div>
    )
}
