const addBooking = (dataArray, initial, start, end, order) => {
  let date = initial;

  // console.log(start, end);

  // console.log("----------------------");
  // console.log(dataArray);

  const _dataArray = dataArray.map((data, index) => {
    // console.log(data);

    if (date.isSame(start, "day")) {
      date = date.add(1, "day");

      if (index !== 0) {
        if (Object.keys(data).length !== 0) {


          return {
            state: 3,
            estate: data,
            sstate: {
              state: 0,
              order,
            },
          };
        }
      }
      
      return {
        ...data,
        state: 0,
        order,
      };
    } else if (date.isAfter(start, "day") && date.isBefore(end, "day")) {
      date = date.add(1, "day");
      return {
        ...data,
        state: 1,
        order,
      };
    } else if (date.isSame(end, "day")) {
      date = date.add(1, "day");
      return {
        ...data,
        state: 2,
        order,
      };
    } else {
      date = date.add(1, "day");
      return data;
    }
  });

  // console.log(_dataArray);
  return _dataArray;
};

export default addBooking;
