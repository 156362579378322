import React, { useRef, useState } from 'react'
import { Badge, Button, Form, Modal, Stack } from 'react-bootstrap'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useReducer } from 'react'

import ImageSelector from '../../components/ImageSelector/ImageSelector'
import './style.css'
import Spinner from '../../components/Spinner/Spinner'
import SpinnerSM from '../../components/Spinner/SpinnerSM';
import { userSelector } from '../../store/reducers/user';
import FacilitySelector from '../../components/FacilitySelector/FacilitySelector';
import target from '../../db/target';

const BadgeCard = ({ facility }) => {

    // console.log("-----------", facility.title);

    const [errorMsgState, setErrorMsgState] = useState(false)
    const [deleteState, setDeleteState] = useState(false)
    const deleteComform = useRef()
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dataHandler = () => {

    }

    return <>
        <Badge pill style={{ marginRight: '5px', marginBottom: '2px', cursor: 'pointer' }} onClick={handleShow}>{facility.title}  </Badge>

        <Modal size='sm' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <label htmlFor="exampleFormControlInput1" className="form-label delete-text ">write "<span>DELETE</span>" to delete</label>
                <input ref={deleteComform} type="test" className="form-control" id="exampleFormControlInput1" />
                {errorMsgState ? <h6 style={{ color: 'red', fontSize: '11px', paddingLeft: '2px', paddingTop: '2px' }}>Enter the word correct</h6> : ''}
            </Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant="secondary" onClick={handleClose}>Close</Button>
                <Button size='sm' variant="danger" onClick={dataHandler}> Delete <SpinnerSM state={deleteState} /></Button>
            </Modal.Footer>
        </Modal>
    </>
}

const EditRoom = ({ room, endPoint, getRooms }) => {

    const authUser = useSelector(userSelector)

    const { _id, group, code, title, description, images, facilities, packageSelect } = room


    const imageReducer = (state, action) => {
        if (action.type === 'selected') {
            return action.images
        }
    }
    const facilityReducer = (state, action) => {
        if (action.type === 'change') {
            return action.facilities.filter(facility => {
                return facility.state === true
            })
        }
    }

    const [selectedImages, imageDispatch] = useReducer(imageReducer, images)
    const [selectedFacilities, facilityDispatch] = useReducer(facilityReducer, facilities)


    const user = useSelector(userSelector)
    const [uploadState, setUpdateState] = useState(false)

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)


    // input group
    const _c = useRef();
    const _t = useRef();
    const _d = useRef();

    function dataHandler() {

        setUpdateState(true)

        const _code = _c.current.value
        const _title = _t.current.value
        const _description = _d.current.value

        if (_code === '' || _title === '') {
            setUpdateState(false)
            alert('Enter all data!')
        } else {

            axios.post(target + 'api/room/update', {
                id: { _id },
                room: {
                    code: _code,
                    title: _title,
                    description: _description,
                    images: selectedImages,
                    facilities: selectedFacilities
                }
            },
                {
                    headers: { 'authorization': `bearer ${authUser ? authUser.accessToken : ''}` }
                })
                .then(res => {
                    // console.log(res)
                    handleClose()
                    
                    getRooms(group)
                    setUpdateState(false)
                })
                .catch(err => {
                    console.log(err)
                    setUpdateState(false)
                    alert(err.message)
                })
        }
    }


    return (
        <div>
            <Button variant="primary" size='sm' onClick={handleShow}>edit</Button>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit the room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupCode">
                            <Form.Label column="sm">Room Code</Form.Label>
                            <Form.Control ref={_c} size="sm" type="number" defaultValue={code} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label column="sm">Room Title</Form.Label>
                            <Form.Control ref={_t} size="sm" type="text" defaultValue={title} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label column="sm">Description</Form.Label>
                            <Form.Control ref={_d} as="textarea" rows={3} size="sm" defaultValue={description} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formImages">
                            <Form.Label column="sm">Images</Form.Label>
                            <div className="room-images-box img-thumbnail" id='images-display'>
                                {
                                    selectedImages.length !== 0
                                        ?
                                        selectedImages.map((img, index) => {
                                            return <img key={index} src={endPoint + img} width={180} className="room-imgs m-1  d-inline" alt="..." />
                                        })
                                        :
                                        <Spinner state={true} />
                                }
                                <ImageSelector dispatch={imageDispatch} imageURL={endPoint} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlFacilitis" >
                            <Form.Label column="sm">Facilities</Form.Label>
                            <div className="room-images-box img-thumbnail" id='images-display'>
                                <Stack direction="horizontal" gap={2} style={{ display: 'inline-block' }} >
                                    {
                                        selectedFacilities.map((facility, index) => {
                                            return <BadgeCard facility={facility} key={index} />
                                        })
                                    }
                                </Stack>
                                <FacilitySelector dispatch={facilityDispatch} facilities={facilities} />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={dataHandler} > Save Changes <SpinnerSM state={uploadState} /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditRoom